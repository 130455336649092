import $ from 'jquery';
import { formatPrice, parseTemplate } from '../common/helpers';
import cartToggle from './cartToggle';

class Cart {
  constructor($element) {
    this.$el = $element;

    this.template = $('#cart__product-template').html();

    this.$total = this.$el.find('.js-total-price');
    this.totalCount = 0;

    this.$totalDelivery = this.$el.find('.js-total-delivery');
    this.delivery = Number.parseFloat(this.$el.find('.js-delivery').text()) || 0;

    this.types = ['refund', 'exchange', 'other'];
    this.$group = {};
    this.$list = {};
    this.$items = {};

    this.initGroups();
    this.recalcTotal();
  }

  initGroups() {
    this.types.forEach((type) => {
      this.$group[type] = this.$el.find(`.js-${type}`);
      this.$list[type] = this.$group[type].find('.cart__list');
      this.$items[type] = this.$list[type].find('.cart__product');
    });
  }

  addProduct(data) {
    const quantities = data.reasons.reduce((acc, value) => {
      const key = value.action.toLowerCase();
      if (typeof acc[key] === 'undefined') {
        acc[key] = 0;
      }
      acc[key] += 1;
      return acc;
    }, {});

    const productHtml = parseTemplate(this.template, data);

    Object.keys(quantities).forEach((type) => {
      const $product = $(parseTemplate(productHtml, {
        quantity: quantities[type],
        sum: formatPrice(data.price * quantities[type]),
        price: formatPrice(data.price * quantities[type]),
      }));

      if (!data.size) {
        $product.find('.product__size').remove();
      }

      this.$list[type].append($product);
      this.$items[type] = this.$items[type].add($product);
      this.toggleBlock(type);
    });

    this.recalcTotal();
  }

  removeProduct(data, isSilently) {
    this.types.forEach((key) => {
      const $removing = this.$items[key].filter(`[data-id="${data.id}"]`).remove();
      this.$items[key] = this.$items[key].not($removing);

      if (!isSilently) {
        this.toggleBlock(key);
      }
    });

    if (!isSilently) {
      this.recalcTotal();
    }
  }

  toggleBlock(type) {
    this.$group[type].toggleClass('hidden', !this.$items[type].length);
    $(`.js-${type}-notice`).toggleClass('hidden', !this.$items[type].length);
  }

  recalcTotal() {
    const self = this;

    // All items in cart
    let $items = $([]);
    this.types.forEach((type) => {
      $items = $items.add(this.$items[type]);
    });

    // Update number on cart toggle
    const prevValue = this.totalCount;
    this.totalCount = $items.length;
    self.totalCount = 0;
    $items.each(function itemsEach() {
      self.totalCount += Number.parseInt($(this).data('quantity'), 10);
    });
    cartToggle.set(this.totalCount);

    if (prevValue < this.totalCount) {
      cartToggle.pulse();
    }

    // Update the total price for refunded items only
    let totalPrice = 0;

    this.$items.refund.each(function itemsEach() {
      // Have to multiply all prices by 100 for avoiding binary floating point
      // https://stackoverflow.com/a/588014/11357125
      totalPrice += Number.parseFloat($(this).data('price')) * 100;
    });

    // Divide by 100 for returning correct price
    this.$total.text(formatPrice(totalPrice / 100));

    // Update the total price with delivery
    this.$totalDelivery.text(formatPrice(this.delivery));
  }
}

Cart.NAMESPACE = '.cart';

export default Cart;
