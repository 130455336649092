/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import { ajaxSettings } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';

const { Base } = window.returns.default;

/**
 * Step1 default page class
 */
class Step1 extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const formSelector = this.options.form || '.find-order__form';
    const validatorOptions = this.options.validator || {};

    this.validator = new Validator(validatorOptions);
    ajaxDefault(this.getCallbacks());
    this.initRMA();

    const $error = $('#error_msg');

    const orderFormAPI = $(formSelector).validate({
      invalidHandler(event, validator, message, target) {
        const $target = (typeof target === 'string') ? $(target) : $error;

        if (message) {
          $target.html(message).show();
        } else {
          $target.hide();
        }
      },

      submitHandler(form) {
        const settings = {
          api: orderFormAPI,
        };
        const debugSettings = {
          page: 'step1',
          actions: ['404', 'not-found', 'rma', 'incorrect-rma'],
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });
  }

  /**
   * Return object with callback functions
   * @returns {Object}
   */
  getCallbacks() {
    const self = this;

    return {
      show_rma() {
        self.toggleRMA(true);
      },
    };
  }

  /**
   * Save RMA associated elements to improve performance and hide it on Order ID change
   */
  initRMA() {
    this.$RMA = $('#js-rma');
    this.$RMAinput = this.$RMA.find('.input');
    this.isRMAVisible = !this.$RMA.hasClass('hidden');

    $('#order_id').on('change', () => {
      // hide RMA input field on order ID change
      this.toggleRMA(false);
    });
  }

  /**
   * Toggle RMA visibility
   * @param {boolean} show
   */
  toggleRMA(show) {
    if (show === this.isRMAVisible) {
      return;
    }

    this.$RMA.toggleClass('hidden', !show);
    this.$RMAinput.prop('disabled', !show);
    this.isRMAVisible = show;
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Step1 } };

export default Step1;
