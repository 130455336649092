/* eslint-disable class-methods-use-this */
/* global google */

import $ from 'jquery';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { i18n, warn } from '../common/helpers';
import RadioGroup from '../imports/RadioGroup';

const { Base } = window.returns.default;

/**
 * Default page class
 */
class Finder extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    this.$map = $('.location__map');
    this.$list = $('.location__list');
    this.$offices = this.$list.find('.office');
    this.$search = $('.location-search__input');

    this.markers = [];
    this.bouncedMarker = null;
    this.isMarkerClicked = false;
    this.bounceClass = 'marker_bounce';

    this.initMap();
    this.initRadioGroup();
    this.createMarkers();
    this.initClusters();
    this.handleChangeCarrier();
    this.handleAutocomplete();
    this.handleUserLocation();
  }

  initMap() {
    this.map = new google.maps.Map(this.$map[0], {
      center: new google.maps.LatLng(0, 0),
      zoom: 1,
      mapTypeControl: true,
      streetViewControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    });

    this.minimalZoom = 13;
  }

  initRadioGroup() {
    this.$list.data('radiogroup', new RadioGroup(this.$list, {
      list: this.options.marks,
      onCheck: ($checked) => {
        const index = this.$offices.index($checked);

        this.bounceMarkerStop();
        this.bounceClusterStop();
        this.bouncedMarker = this.markers[index];
        this.bounceMarkerStart();

        if (!this.isMarkerClicked) {
          // If office was clicked
          this.map.setZoom(this.minimalZoom);
          this.map.panTo({
            lat: Number.parseFloat($checked.data('lat')),
            lng: Number.parseFloat($checked.data('lng')),
          });
        }

        // Reset marker clicked flag
        this.isMarkerClicked = false;
      },
    }));
  }

  createMarkers() {
    if (!this.$offices.length) {
      return;
    }

    const latLngBounds = new google.maps.LatLngBounds();

    this.$offices.each((index, office) => {
      const $office = $(office);

      const marker = new google.maps.Marker({
        position: {
          lat: Number.parseFloat($office.data('lat')),
          lng: Number.parseFloat($office.data('lng')),
        },
        draggable: false,
        icon: {
          path: 'M7 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zM7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z',
          fillColor: '#03a8a0',
          fillOpacity: 1,
          scale: 2,
          strokeColor: '#fff',
          strokeWeight: 1,
          anchor: new google.maps.Point(7, 20),
        },
        title: $office.find('.office__title').text(),
      });

      marker.addListener('click', () => {
        this.isMarkerClicked = true;
        $office.trigger('click.radiogroup');

        this.$list.animate({
          scrollTop: this.$list.scrollTop() + $office.position().top,
        }, 500);
      });

      latLngBounds.extend(marker.position);
      this.markers.push(marker);
    });

    this.map.setCenter(latLngBounds.getCenter());
    this.map.fitBounds(latLngBounds);
  }

  initClusters() {
    const markerCluster = new MarkerClusterer(this.map, this.markers, {
      ignoreHidden: true,
      gridSize: 80,
      maxZoom: 14,
      styles: [
        {
          width: 35,
          height: 35,
          className: 'cluster-icon-s',
        },
        {
          width: 40,
          height: 40,
          className: 'cluster-icon-m',
        },
        {
          width: 45,
          height: 45,
          className: 'cluster-icon-l',
        },
      ],
      clusterClass: 'cluster-icon',
    });

    this.map.addListener('idle', () => {
      if (this.bouncedMarker) {
        // If marker was clicked - animate it each time cluster updated
        this.bounceMarkerStart();

        setTimeout(() => {
          // After zooming out cluster is not animated, only after map pan
          // So should to add small pause after idle event
          // There is no public access to cluster, so have to use private properties
          /* eslint-disable-next-line no-underscore-dangle */
          markerCluster.clusters_.some((cluster) => {
            if (cluster.isMarkerInClusterBounds(this.bouncedMarker)) {
              // If cluster with bounced marker is changed - animate new cluster
              this.bounceClusterStop();
              this.bounceClusterStart(cluster);
              return true;
            }

            return false;
          });
        }, 100);
      }
    });
  }

  handleChangeCarrier() {
    const $form = $('.finder__form');
    const $carrier = $('#drop-offs-carrier');

    $carrier.on('change', () => $form[0].submit());
  }

  handleAutocomplete() {
    const searchBox = new google.maps.places.SearchBox(this.$search[0]);

    this.map.addListener('bounds_changed', () => searchBox.setBounds(this.map.getBounds()));

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length !== 0) {
        // If user have chosen any element from search results then move map
        this.map.setZoom(this.minimalZoom);
        this.map.setCenter(places[0].geometry.location);
      }
    });

    this.$search.on('keydown', (event) => {
      if (event.keyCode === 13) {
        // Don't send a full form if user pressed "Enter" key
        event.preventDefault();
      }
    });
  }

  handleUserLocation() {
    if (!window.navigator.geolocation) {
      return;
    }

    const $gps = $('.location-search__button_gps');
    const geocoder = new google.maps.Geocoder();
    let userMarker = null;
    const gpsErrorClass = 'location-search__button_gps_error';

    this.$search.addClass('location-search__input_gps');

    $gps.on('click', (event) => {
      event.preventDefault();

      $gps.removeClass(gpsErrorClass);
      this.constructor.toggleButton($gps, true, i18n('Looking for your position...'));

      navigator.geolocation.getCurrentPosition((position) => {
        // Get user location
        const userLocation = new google.maps.LatLng(
          position.coords.latitude, position.coords.longitude,
        );

        geocoder.geocode({
          latLng: userLocation,
        }, (results, status) => {
          this.constructor.toggleButton($gps, false, i18n('Find near my location'));

          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            this.$search.val(results[0].formatted_address);

            if (userMarker) {
              userMarker.setPosition(userLocation);
            } else {
              userMarker = new google.maps.Marker({
                position: userLocation,
                map: this.map,
                icon: {
                  path: 'M7 6a3 3 0 0 1 0-6 3 3 0 0 1 0 6zM7 7C3.13 7 0 10.13 0 14c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z',
                  fillColor: '#000',
                  fillOpacity: 1,
                  scale: 1.35,
                  strokeColor: '#fff',
                  strokeWeight: 0.5,
                  anchor: new google.maps.Point(7, 27),
                },
                title: i18n('Your location'),
              });
            }

            this.map.setZoom(this.minimalZoom);
            this.map.setCenter(userLocation);
          }
        });
      }, (error) => {
        warn(error.message);

        let message = '';
        let isError = false;
        switch (error.code) {
          case 1: // PERMISSION_DENIED
            message = i18n('User denied Geolocation');
            isError = true;
            break;
          case 2: // POSITION_UNAVAILABLE
            message = i18n('Position unavailable');
            isError = true;
            break;
          case 3: // TIMEOUT
            message = i18n('Timeout expired');
            break;
          default: // Something else
            message = i18n('Unexpected error');
            break;
        }

        $gps.toggleClass(gpsErrorClass, isError);
        this.constructor.toggleButton($gps, false, message);
      }, { timeout: 5000 });
    });
  }

  bounceMarkerStop() {
    if (this.bouncedMarker) {
      // Stop animating previously clicked marker
      this.bouncedMarker.setAnimation(-1);
      this.bouncedMarker.setZIndex(1);
    }
  }

  bounceMarkerStart() {
    this.bouncedMarker.setAnimation(google.maps.Animation.BOUNCE);
    this.bouncedMarker.setZIndex(100);
  }

  bounceClusterStart(cluster) {
    // There is no public access to cluster icon, so have to use private properties
    /* eslint-disable-next-line no-underscore-dangle */
    $(cluster.clusterIcon_.div_).addClass(this.bounceClass);
  }

  bounceClusterStop() {
    $(`.${this.bounceClass}`).removeClass(this.bounceClass);
  }

  static toggleButton($button, isDisabled, message) {
    $button
      .prop('disabled', isDisabled)
      .attr('title', message)
      // .attr('aria-label', message);
      .find('.hidden_sr')
      .text(message);
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Finder } };

export default Finder;
