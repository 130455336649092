import $ from 'jquery';
import 'jquery.mmenu/dist/jquery.mmenu.all'; // TODO Use custom build instead of full script
import { checkScrollBar, getScrollbar, parseTemplate } from '../common/helpers';
import Reason from './Reason';

class Popup {
  constructor($element, reasons, commentMaxLength) {
    this.$el = $element;
    this.reasons = reasons;
    this.commentMaxLength = commentMaxLength;

    this.isDesktop = $(window).width() >= 576;

    this.panelTemplate = $('#reason-popup__panel-template').html();

    this.initMmenu();

    window.matchMedia('(min-width: 576px)').addEventListener('change', (mq) => {
      this.isDesktop = mq.matches;

      // TODO optimize - do not remove/add menu after each toggling media query
      if (this.$mmenu.hasClass('mm-menu_opened')) {
        this.api.bind('close:finish', () => {
          this.$mmenu.remove();
          this.initMmenu();
        });

        this.api.close();
      } else {
        this.$mmenu.remove();
        this.initMmenu();
      }
    });
  }

  initMmenu() {
    const self = this;

    const $body = $('body');
    const scrollbarWidth = getScrollbar();

    this.$mmenu = this.$el.mmenu({
      extensions: [
        'pagedim-black',
        this.isDesktop ? 'popup' : 'position-bottom',
        'fx-panels-slide-up', // 'fx-panels-none'
      ],

      hooks: {
        'initMenu:after': function initMenuHook() {
          this.$menu.height(305);
        },

        'open:before': () => {
          if (checkScrollBar()) {
            $body.css('padding-right', scrollbarWidth);
          }
        },

        'close:finish': function closeFinishHook() {
          this.closeAllPanels();
          self.clearPopup();
          this.$menu.height(305);

          $body.css('padding-right', '');
        },
      },

      navbar: false,

      autoHeight: true,
    }, {
      clone: true,
    });

    this.api = this.$mmenu.data('mmenu');
    this.$quantityForm = this.$mmenu.find('.reason-popup__form_quantity');
    this.$quantity = this.$quantityForm.find('.js-quantity');
    this.$panels = $();

    this.handleBack();
    this.handleQuantityPanel();
  }

  clearPopup() {
    // Remove panels with reasons
    this.$panels.remove();
    this.$panels = $();
  }

  handleBack() {
    const self = this;

    this.$mmenu.on(`click${Popup.NAMESPACE}`, '.reason-popup__back', function backClick(event) {
      event.preventDefault();
      const $prevPanel = $(this).closest(Popup.PANEL).prev(Popup.PANEL);

      if ($prevPanel.length) {
        // Open previous panel if current is not the first
        self.api.openPanel($prevPanel);
      } else {
        // Close menu if current in the first
        self.api.close();
      }
    });
  }

  open(product) {
    this.product = product;

    // Fill the quantity dropdown with available amount
    this.fillQuantity(this.product.available, this.product.quantity);
    if (this.product.reasons.length) {
      this.addPanels(this.product.reasons);
    }
    this.api.open();
  }

  fillQuantity(available, current) {
    let $options = $();

    for (let i = 0; i <= available; i += 1) {
      // If quantity is not specified - select the maximal value
      const isSelected = current === 0 ? i === available : i === current;
      $options = $options.add(`<option value="${i}" ${isSelected ? 'selected' : ''}>${i}</option>`);
    }

    this.$quantity.empty().append($options);
  }

  handleQuantityPanel() {
    this.$quantityForm.on(`submit${Popup.NAMESPACE}`, (event) => {
      event.preventDefault();

      const quantity = Number.parseInt(this.$quantity.val(), 10);

      if (quantity === 0) {
        // Zero quantity
        this.product.createReasonInputs([]);
        this.api.close();
        return;
      }

      if (quantity > this.$panels.length) {
        // Need to add panels to popup
        this.addPanels();
      } else if (quantity < this.$panels.length) {
        // Need to remove panels from popup
        this.removePanels(quantity);
      }

      // Open first panel
      this.api.openPanel(this.$panels.first());
    });
  }

  createPanel(index) {
    return $(parseTemplate(this.panelTemplate, {
      index,
      counter: index + 1,
    }));
  }

  addPanels(initial = []) {
    const self = this;

    if (!this.$panels.length) {
      const i = 0;
      const $panel = this.createPanel(i);
      this.api.initPanels($panel);
      this.$panels = this.$panels.add($panel);

      const $reasons = $panel.find('.reasons');
      if (typeof initial[i] !== 'undefined') {
        $reasons.data('reasons', initial[i]);
      }
      $reasons.data('reason', new Reason($reasons, {
        name: 'reasons',
        index: i,
        reasons: this.reasons,
        commentMaxLength: this.commentMaxLength,
        additionalClass: 'input_size_sm',
        callbacks: {
          action() {
            // "this" refers to "Action" select
            $panel.find('.js-exchange-warning').toggleClass('hidden', $(this).val() !== 'Exchange');
          },
        },
      }));
      $reasons.on('change', 'select', () => {
        self.resizeMmenu();
      });

      $panel.find('form').validate({
        invalidHandler() {
          // handler is fired before the error message shown, so we need the delay before resize
          setTimeout(() => {
            self.resizeMmenu();
          }, 1);
        },
        submitHandler(form) {
          const $nextPanel = $(form).closest(Popup.PANEL).next(Popup.PANEL);

          if ($nextPanel.length) {
            self.api.openPanel($nextPanel);
          } else {
            const quantity = Number.parseInt(self.$quantity.val(), 10);
            const reason = self.$mmenu.find('.reasons').data('reason').getValue();
            const reasons = Array(quantity).fill(reason);

            self.product.createReasonInputs(reasons);
            self.api.close();
          }
        },
      });
    }
  }

  removePanels(quantity) {
    const $removing = this.$panels.slice(quantity);
    $removing.remove();
    this.$panels = this.$panels.not($removing);
  }

  resizeMmenu() {
    // TODO optimize
    // height transition enabled by default, so adding new dropdowns triggers ugly animation
    // so we remove transition, add dropdown, change height and add transition again
    this.$mmenu.removeClass('mm-menu_autoheight');
    this.api.getInstance().trigger('updateListview'); // autoheight plugin binded to this event
    setTimeout(() => {
      this.$mmenu.addClass('mm-menu_autoheight');
    }, 1);
  }
}

Popup.NAMESPACE = '.popup';
Popup.PANEL = '.mm-panel';

export default Popup;
