// Replace UTC Date format to local by user OS environment
// For use:
// 1. On the page add js-localtime class and dataset data-timestamp = "timestamp" (UTC)
//    to the element for which you want to convert the time to a local value.
// 2. Import this script to the appropriate page and execute.

export default function () {
  document.querySelectorAll('.js-localtime').forEach((item) => {
    item.innerHTML = new Date(item.dataset.timestamp * 1000).toLocaleString("sv-SE");
  });
}
