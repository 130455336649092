/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import { ajaxSettings } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';
import cartDropdown from '../imports/cartDropdown';
import cartToggle from '../imports/cartToggle';
import Customer from '../imports/Customer';

const { Base } = window.returns.default;

/**
 * Confirm default page class
 */
class Confirm extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const validatorOptions = this.options.validator || {};
    const customerSelector = this.options.customerSelector || '.customer';

    this.validator = new Validator(validatorOptions);
    ajaxDefault();

    cartDropdown();
    let cartQuantity = 0;
    $('.cart__product[data-quantity]').each((index, element) => {
      cartQuantity += Number.parseInt($(element).data('quantity'), 10);
    });
    cartToggle.set(cartQuantity);

    const $customer = $(customerSelector);
    const customerApi = new Customer($customer, this.options);
    $customer.data('customer', customerApi);

    const self = this;
    this.$suggestions = $('.js-suggestion');
    this.$buttonGroup = $('.js-suggestion-group');
    this.$button = $('.js-suggestion-all');

    // Remove state suggestion if the user has changed the country
    customerApi.$country.on('change', () => {
      this.removeSuggestion(this.$suggestions.filter(`[for="${customerApi.$state.attr('id')}"]`));
      this.removeSuggestion(this.$suggestions.filter(`[data-target="${customerApi.$state.attr('id')}"]`));
    });

    const $error = $('#error_msg');

    const confirmFormAPI = $('.confirm__form').validate({
      invalidHandler(event, validator, msg) {
        if (msg) {
          $error.html(msg).show();
        } else {
          $error.hide();
        }
      },

      submitHandler(form) {
        const settings = {
          api: confirmFormAPI,
        };
        const debugSettings = {
          page: 'confirm',
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });

    // Change input value on suggestion click
    this.$suggestions.on('click', function suggestionClick(e) {
      e.preventDefault();
      const $suggestion = $(this);
      const value = $suggestion.data('value');
      const $input = $(`#${$suggestion.attr('for') || $suggestion.data('target')}`);

      if ($input.is('select')) {
        // Find option with value or placeholder and choose last of them
        $input.find(`option[value="${value}"], option:not([value])`).last().prop('selected', true);
      } else {
        $input.val(value);
      }

      $input.trigger('change');
      $input.valid();
      self.removeSuggestion($suggestion);
    });

    // Apply all suggestions and submit form
    this.$button.on('click', () => this.$suggestions.filter(':visible').trigger('click'));
  }

  removeSuggestion($suggestion) {
    $suggestion.closest('.confirm-form__suggestion-wrap').addBack().remove();
    this.$suggestions = this.$suggestions.not($suggestion);

    if (!this.$suggestions.filter(':visible').length) {
      this.$buttonGroup.addClass('hidden');
    }
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Confirm } };

export default Confirm;
