/* eslint-disable class-methods-use-this */

import Option from './Option';

class Courier extends Option {
  init() {
    this.$wrap = this.$el.find(this.constructor.ITEM_WRAP).addBack(this.constructor.ITEM_WRAP);
  }

  checkRadioPresence() {}

  handleElementEvents() {
    const self = this;

    this.$wrap.on(`click${self.constructor.NAMESPACE}`, () => {
      // TODO test in all browsers
      // change radio button state
      self.$radio.trigger(`click${self.constructor.NAMESPACE}`);
    });
  }
}

const NAME = 'courier';

Courier.ITEM = `.${NAME}`;
Courier.ITEM_SELECTED = `${NAME}_selected`;
Courier.ITEM_DISABLED = `${NAME}_disabled`;
Courier.ITEM_WRAP = `.${NAME}__wrap`;
Courier.RADIO = '.radio__input';
Courier.NAMESPACE = `.${NAME}`;

export default Courier;
