import $ from 'jquery';

class Gallery {
  constructor() {}

  static getParams(src) {
    const dfd = $.Deferred();
    const tmpImage = document.createElement('img');

    tmpImage.onload = () => dfd.resolve({
      src: tmpImage.src,
      ratio: tmpImage.width / tmpImage.height,
    });

    tmpImage.src = src;

    return dfd.promise();
  }

  static load($image, shownClass = Gallery.SHOWN, figureRatio = null) {
    return Gallery.getParams($image.data('src')).done((imgData) => {
      $image.attr('src', imgData.src).addClass(shownClass);

      if (figureRatio) {
        const minDiff = 0.1;
        if (imgData.ratio < figureRatio * (1 - minDiff) || imgData.ratio > figureRatio * (1 + minDiff)) {
          $image.addClass('contain');
        }
      }
    });
  }
}

const NAME = 'gallery';

Gallery.IMAGE = `.${NAME}__image`;
Gallery.SHOWN = `${NAME}__image_shown`;

export default Gallery;
