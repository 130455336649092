/* eslint-disable class-methods-use-this */
/* global google */

import Option from './Option';

class Office extends Option {
  init(marker, radioName) {
    this.marker = marker;
    this.radioName = radioName;

    this.animated = false;

    if (this.marker) {
      this.marker.addListener('click', () => this.select());
    }

    this.toggleBounce(this.selected);
  }

  update() {
    super.update();

    this.toggleBounce(this.selected);
  }

  toggleBounce(toggle) {
    if (toggle === this.animated) {
      // State wasn't changed
      return false;
    }

    if (this.marker) {
      if (toggle) {
        this.marker.setAnimation(google.maps.Animation.BOUNCE);
        this.marker.setZIndex(100);
      } else {
        // using -1 instead of null
        // https://stackoverflow.com/a/38921305
        this.marker.setAnimation(-1);
        this.marker.setZIndex(1);
      }
    }

    this.animated = toggle;

    // State was changed
    return true;
  }

  hide() {
    if (this.$el.is(':hidden')) {
      return;
    }

    this.deselect();
    this.$el.hide();

    if (this.marker) {
      this.marker.setVisible(false);
    }
  }

  show() {
    if (this.$el.is(':visible')) {
      return;
    }

    this.$el.show();

    if (this.marker) {
      this.marker.setVisible(true);
    }
  }

  getMarker() {
    return this.marker;
  }
}

const NAME = 'office';

Office.ITEM = `.${NAME}`;
Office.ITEM_SELECTED = `${NAME}_selected`;
Office.ITEM_DISABLED = `${NAME}_disabled`;
Office.RADIO = '.radio__input';
Office.NAMESPACE = `.${NAME}`;

export default Office;
