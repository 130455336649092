/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import moment from 'moment';
// import 'moment/min/locales'; // TODO very heavy, try to replace with server strings
import 'eonasdan-bootstrap-datetimepicker';
import tippy from 'tippy.js';
import { ajaxSettings } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';
import cartDropdown from '../imports/cartDropdown';
import cartToggle from '../imports/cartToggle';
import Location from '../imports/Location';
import Courier from '../imports/Courier';
import Office from '../imports/Office';
import itemsSummary from '../imports/itemsSummary';

const { Base } = window.returns.default;

/**
 * Shipping default page class
 */
class Shipping extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    tippy('.courier__tooltip');

    const validatorOptions = this.options.validator || {};

    this.validator = new Validator(validatorOptions);
    ajaxDefault();

    itemsSummary();

    const $html = $('html');
    const direction = $html.attr('dir');

    cartDropdown();
    let cartQuantity = 0;
    $('.cart__product[data-quantity]').each((index, element) => {
      cartQuantity += Number.parseInt($(element).data('quantity'), 10);
    });
    cartToggle.set(cartQuantity);

    $.fn.datetimepicker.defaults = {
      ...$.fn.datetimepicker.defaults,
      ...{
        widgetPositioning: {
          horizontal: (direction === 'ltr') ? 'left' : 'right',
          vertical: 'bottom',
        },
        locale: $html.attr('lang'),
        focusOnShow: true,
        ignoreReadonly: true,
        icons: {
          time: 'icon icon_clock',
          date: 'icon icon_calendar',
          up: 'icon icon_chevron-up',
          down: 'icon icon_chevron-down',
          previous: 'icon icon_chevron-left',
          next: 'icon icon_chevron-right',
          today: 'icon icon_crosshairs',
          clear: 'icon icon_trash',
          close: 'icon icon_remove',
        },
      },
    };

    $('.js-time-from').datetimepicker({
      format: 'HH:mm',
    });
    $('.js-time-to').datetimepicker({
      widgetPositioning: {
        horizontal: (direction === 'ltr') ? 'right' : 'left',
      },
      format: 'HH:mm',
    });

    const $steps = $('.steps');
    const $pudoLink = $('#no-pudo-locator-link');

    const $location = $('.location');
    $location.data('location', new Location($location, {
      points: this.options.marks,
      icons: this.options.marks_icons,
      zip: this.options.marks_zip,
      country: this.options.country,
      message: this.options.message, // If there are no points then message will be defined
      center: this.options.center, // If points are present then center will be defined
      requiredDrops: this.options.carriers_select_drop_off_points,
      searchWidgetClass: this.options.search_widget,
      onRefresh: () => {
        $(Courier.ITEM).each((index, element) => {
          const $courier = $(element);
          const data = $courier.data('courier');
          if (data) {
            data.deselect();
          }
        });

        $steps.attr('data-pay', 0);
        this.disabledServices();

        $pudoLink.addClass('hidden');
      },
    }));

    const $error = $('#error_msg');
    const $couriers = $(Courier.ITEM);

    if ($couriers.length === 0) {
      $('.services-list').hide();
    }

    const $locationList = $('.location__list');
    $couriers.not('.shipping__courier_button').each((index, element) => {
      const $courier = $(element);
      $courier.data('courier', new Courier($courier));

      const maintenance = this.options.carriers_maintenance[$courier.data('carrier')];
      if (maintenance) {
        $courier.data('courier').disable();
        tippy($courier.find('.courier__state')[0], {
          content: maintenance,
          allowHTML: true,
        });
      }
    }).on('change', function courierChange() {
      $error.hide();

      const serviceId = $(this).data('service');
      const carrierId = $(this).data('carrier');
      const externalPudoLocatorUrl = $(this).data('locator');

      // detaching offices increases performance
      const $offices = $(Office.ITEM).detach();
      const selector = `[data-service="${serviceId}"][data-carrier="${carrierId}"]`;
      const $officesWithSelector = $offices.filter(selector);
      $officesWithSelector.each((index, element) => {
        const data = $(element).data('office');
        if (data) {
          // Office methods don't work for detached elements (why?), so we should control
          // visibility manually
          // data.show();
          $(element).show();
          data.marker.setVisible(true);
        }
      });

      $('#js-map').parent().toggleClass('hidden', !$officesWithSelector.length);
      $('#no-pudo-message').toggleClass('hidden', !!$officesWithSelector.length);
      $pudoLink.toggleClass('hidden', !externalPudoLocatorUrl).attr('href', externalPudoLocatorUrl || '#');

      $offices.not(selector).each((index, element) => {
        const data = $(element).data('office');
        if (data) {
          // see comment above
          // data.hide();
          data.deselect();
          $(element).hide();
          data.marker.setVisible(false);
        }
      });
      // return offices to the DOM
      $locationList.append($offices);
      // refresh clusters
      $location.data('location').markerCluster.repaint();

      if ($(this).find(':radio').data('free')) {
        $steps.attr('data-pay', 0);
      } else {
        $steps.attr('data-pay', 1);
      }

      const $additionalFields = $('.js-additional-fields');
      $additionalFields.filter(`[data-carrier="${carrierId}"]`).each((index, element) => {
        const $fields = $(element);
        const fieldsServiceId = $fields.data('service');
        const showFields = fieldsServiceId === undefined || fieldsServiceId === serviceId;
        $fields.toggleClass('hidden', !showFields).find('input, select').prop('disabled', !showFields);
      });
      $additionalFields.not(`[data-carrier="${carrierId}"]`).addClass('hidden').find('input, select').prop('disabled', true);

      const $homeCollection = $(`#${$(this).data('home-collection')}_settings`);
      $homeCollection.removeClass('hidden').find('input, select').prop('disabled', false);
      $('.js-home-collection').not($homeCollection).addClass('hidden')
        .find('input, select')
        .prop('disabled', true);

      $('.js-point-required').toggleClass('hidden', !$(this).data('point-required'));
    });
    this.disabledServices();

    const $activeCouriers = $couriers.not(`.${Courier.ITEM_DISABLED}`);
    if ($activeCouriers.length === 1) {
      $activeCouriers.data('courier').select();
    }

    this.globalDatesArray = {};
    this.timeslotsByDate = {};
    const collectionDates = this.options.collection_dates;

    Object.keys(collectionDates).forEach((service) => {
      const datesArray = collectionDates[service];
      const firstDate = moment(datesArray[0].date, 'YYYY-MM-DD');
      const lastDate = moment(datesArray[datesArray.length - 1].date, 'YYYY-MM-DD');
      const $settings = $(`#${service}_settings`);
      this.globalDatesArray[service] = {};
      this.timeslotsByDate[service] = {};
      const availableDates = [];
      const availableTime = {};

      firstDate.set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      });
      lastDate.set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      });

      datesArray.forEach((value) => {
        const date = moment(value.date).set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).valueOf();

        if (!this.timeslotsByDate[service][date]) {
          this.timeslotsByDate[service][date] = [];
        }
        this.timeslotsByDate[service][date].push(`${value.time_from}-${value.time_to}`);

        availableDates.push(date);
        availableTime[date] = {};
        availableTime[date].from = value.time_from || false;
        availableTime[date].to = value.time_to || false;
        availableTime[date].min = value.minimum_interval || false;
      });

      this.globalDatesArray[service].availableTime = availableTime;

      if ($settings.length) {
        const $datepicker = $settings.find('.js-date');

        if ($datepicker.length) {
          $datepicker.data('service-name', service);
          $datepicker.datetimepicker({
            useCurrent: false,
            format: 'DD-MM-YYYY',
            minDate: firstDate,
            maxDate: lastDate,
            defaultDate: firstDate,
            enabledDates: availableDates,
            focusOnShow: false,
          });

          this.setAvailableTime($datepicker);
          $datepicker.on('dp.change', () => this.setAvailableTime($datepicker));
        }
      }
    });

    const shippingFormAPI = $('.shipping-form').validate({
      invalidHandler(event, validator, msg) {
        if (msg) {
          $error.html(msg).show();
        } else {
          $error.hide();
        }
      },

      errorPlacement(error, element) {
        if (element.is(':radio')) {
          $error.text(error.text()).show();
        } else {
          element.after(error);
        }
      },

      submitHandler(form) {
        const settings = {
          api: shippingFormAPI,
        };
        const debugSettings = {
          page: 'shipping',
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });
  }

  disabledServices() {
    $(Courier.ITEM).not('.shipping__courier_button').each((index, element) => {
      const $courier = $(element);
      const carrierId = $courier.data('carrier');

      if (this.options.carriers_maintenance[carrierId]) {
        return;
      }

      if ($courier.data('home-collection')) {
        return;
      }

      const $offices = $(Office.ITEM);
      const selector = `[data-service="${$courier.data('service')}"]`;
      const count = $offices.filter(selector).length;
      const state = $courier.find('.courier__state');
      // eslint-disable-next-line no-underscore-dangle
      const tippyInstance = state[0]._tippy;

      if (this.options.carrier_drop_off[carrierId] && count === 0) {
        $courier.data('courier').disable();

        if (tippyInstance) {
          tippyInstance.enable();
        } else {
          tippy(state[0], {
            content: this.options.disabled_service_msg,
          });
        }
      } else {
        $courier.data('courier').enable();

        if (tippyInstance) {
          tippyInstance.disable();
        }
      }
    });
  }

  setAvailableTime($datepicker) {
    const current_date = new Date($datepicker.data('DateTimePicker').date());
    const service_name = $datepicker.data('service-name');
    const settings_blk = $(`#${service_name}_settings`);
    const collection_time_slot = settings_blk.find('.js-collection-time-slot');
    const time_from_blk = settings_blk.find('.js-time-from');
    const time_to_blk = settings_blk.find('.js-time-to');

    if (time_from_blk.length && time_to_blk.length) {
      const available_time_for_date = this.globalDatesArray[service_name].availableTime[+current_date];
      const time_from = available_time_for_date.from ? available_time_for_date.from.split(':') : false;
      const time_to = available_time_for_date.to ? available_time_for_date.to.split(':') : false;
      const min_diff = available_time_for_date.min ? available_time_for_date.min : 0;
      const hours_from = time_from ? parseInt(time_from[0], 10) : 0;
      const minutes_from = time_from ? parseInt(time_from[1], 10) : 0;
      const hours_to = time_to ? parseInt(time_to[0], 10) : 23;
      const minutes_to = time_to ? parseInt(time_to[1], 10) : 59;
      const enabledHFrom = [];
      const enabledHTo = [];
      const min_time = current_date.setHours(hours_from, minutes_from, 0, 0);
      const max_time = new Date(current_date.setHours(hours_to, minutes_to, 0, 0));

      for (let i = hours_from; i <= (hours_to - min_diff); i++) {
        enabledHFrom.push(i);
      }
      for (let i = (hours_from + min_diff); i <= hours_to; i++) {
        enabledHTo.push(i);
      }

      if (time_from_blk.length) {
        time_from_blk.data('service-name', service_name);

        const data = time_from_blk.data('DateTimePicker');
        data.enabledHours(enabledHFrom);
        data.date(moment(min_time));
      }

      if (time_to_blk.length) {
        time_to_blk.data('service-name', service_name);

        const data = time_to_blk.data('DateTimePicker');
        data.enabledHours(enabledHTo);
        data.date(moment(max_time));
      }

      time_from_blk.on('dp.change', (e) => {
        const service_name = time_from_blk.data('service-name');
        const datepicker_blk = $(`#${service_name}_settings`).find('.js-date');
        const current_date = new Date(datepicker_blk.data('DateTimePicker').date());
        const fd = new Date(e.date);
        const fd_h = fd.getHours();
        const fd_m = fd.getMinutes();
        const td = new Date(time_to_blk.data('DateTimePicker').date());
        const td_h = td.getHours();
        const td_m = td.getMinutes();
        const fd_full = fd_h * 60 + fd_m;
        const td_full = td_h * 60 + td_m;
        const diff = td_full - fd_full;

        const min_diff = this.globalDatesArray[service_name].availableTime[+current_date].min;
        if (diff < min_diff * 60) {
          fd.setHours(fd_h + min_diff);
          time_to_blk.data('DateTimePicker').date(fd);
        }
      });

      time_to_blk.on('dp.change', (e) => {
        const service_name = time_to_blk.data('service-name');
        const datepicker_blk = $(`#${service_name}_settings`).find('.js-date');
        const current_date = new Date(datepicker_blk.data('DateTimePicker').date());
        const td = new Date(e.date);
        const td_h = td.getHours();
        const td_m = td.getMinutes();
        const fd = new Date(time_from_blk.data('DateTimePicker').date());
        const fd_h = fd.getHours();
        const fd_m = fd.getMinutes();
        const td_full = td_h * 60 + td_m;
        const fd_full = fd_h * 60 + fd_m;
        const diff = td_full - fd_full;
        const min_diff = this.globalDatesArray[service_name].availableTime[+current_date].min;

        if (diff < min_diff * 60) {
          td.setHours(td_h - min_diff);
          time_from_blk.data('DateTimePicker').date(td);
        }
      });
    }

    if (collection_time_slot.length) {
      collection_time_slot.empty();
      const cur_month_number = current_date.getMonth() + 1;
      const cur_day_with_zero = current_date.getDate() < 10 ? ("0" + current_date.getDate()) : current_date.getDate();
      const cur_month_with_zero = cur_month_number < 10 ? ("0" + cur_month_number) : cur_month_number;
      const cur_date_str = current_date.getFullYear() + '-' + cur_month_with_zero + '-' + cur_day_with_zero;

      for (let time_key in this.timeslotsByDate[service_name][+current_date]) {
        const option_val = this.timeslotsByDate[service_name][+current_date][time_key];
        collection_time_slot.append($('<option>', {
          value: option_val,
          text: option_val,
        }));
      }
    }
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Shipping } };

export default Shipping;
