import $ from 'jquery';

class Accordion {
  constructor($element) {
    this.$el = $element;
    this.$items = this.$el.find(this.constructor.ITEM);
    this.$toggles = this.$el.find(this.constructor.TOGGLE);
    this.$wraps = this.$el.find(this.constructor.WRAP);

    this.$toggles.attr('aria-expanded', false);

    this.$items.each((index, element) => {
      const $item = $(element);
      const $toggle = $item.find(this.constructor.TOGGLE);
      const $wrap = $item.find(this.constructor.WRAP);

      $toggle.on(`click${this.constructor.NAMESPACE}`, () => {
        this.$wraps.not($wrap).removeClass('accordion__wrap_shown');
        $wrap.toggleClass('accordion__wrap_shown');

        this.$toggles.not($toggle).attr('aria-expanded', false);
        $toggle.attr('aria-expanded', $toggle.attr('aria-expanded') === 'false');
      });
    });
  }
}

const NAME = 'accordion';

Accordion.ITEM = `.${NAME}__item`;
Accordion.TOGGLE = `.${NAME}__toggle`;
Accordion.WRAP = `.${NAME}__wrap`;
Accordion.NAMESPACE = `.${NAME}`;

export default Accordion;
