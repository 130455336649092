import $ from 'jquery';
import { parseTemplate, warn } from '../common/helpers';
import OrderProduct from './OrderProduct';
import Reason from './Reason';

class Order {
  constructor($element, options = {}, params = {}) {
    if (!$element.length) {
      warn('Order requires an element');
      return;
    }

    this.options = {
      ...Order.DEFAULTS,
      ...params,
    };
    this.productOptions = {
      ...OrderProduct.DEFAULTS,
      ...params.product,
    };

    this.$el = $element;
    this.$list = this.$el.find('.order__list');
    this.reasons = options.reasons;
    this.commentMaxLength = options.commentMaxLength;

    this.template = $(this.options.PRODUCT).html();
    this.$add = $(this.options.ADD);
    this.unremovable = false;

    this.$products = $(this.productOptions.PRODUCT);
    this.nextIndex = this.$products.length;

    this.setCurrency(options.currency);
    this.handleAdd();

    if (this.$products.length) {
      this.$products.each((index, product) => {
        this.initProduct(index, $(product));
      });

      if (this.$products.length === 1) {
        this.unremovable = this.$products;
      }
    } else {
      this.unremovable = this.addProduct();
    }

    if (this.unremovable) {
      this.unremovable.data('product').setAsUnremovable();
    }
  }

  initProduct(index, $product) {
    $product.data({
      product: new OrderProduct($product, {
        currency: this.currency,
      }, this.productOptions),
      index,
    }).on(`remove${this.productOptions.NAMESPACE}`, () => {
      this.removeProduct();
    });

    if (this.reasons) {
      $product.data('reason', new Reason($product, {
        name: 'items_info',
        index,
        reasons: this.reasons,
        commentMaxLength: this.commentMaxLength,
        isOrder: true,
      }));
    }
  }

  addProduct() {
    const $product = $(parseTemplate(this.template, { index: this.nextIndex }));
    this.initProduct(this.nextIndex, $product);
    this.nextIndex += 1;

    // TODO enable reason chooser module here
    if (this.unremovable) {
      this.unremovable.data('product').setAsRemovable();
      this.unremovable = false;
    }

    this.$list.append($product);
    this.$products = this.$products.add($product);
    return $product;
  }

  removeProduct() {
    const $products = this.$products.filter(`:not(.${this.productOptions.REMOVING})`);

    if ($products.length) {
      this.nextIndex = $products.last().data('index') + 1;

      if ($products.length === 1) {
        this.unremovable = $products;
        $products.data('product').setAsUnremovable();
      }
    } else {
      this.nextIndex = 0;
    }
  }

  handleAdd() {
    this.$add.on(`click${this.options.NAMESPACE}`, () => {
      this.addProduct();
    });
  }

  setCurrency(currency = this.productOptions.CURRENCY) {
    this.currency = currency;
    this.$products.trigger(`currency${this.productOptions.NAMESPACE}`, [currency]);
  }
}

Order.DEFAULTS = {
  ELEMENT: '.order',
  PRODUCT: '#order__product-template',
  ADD: '.button_add',
  ACTION: '.order__group_action',
  REASON: '.order__group_reason',
  WISH: '.order__group_wish',
  COMMENT: '.order__group_comment',
  NAMESPACE: '.order',
};

export default Order;
