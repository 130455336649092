/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import { ajaxSettings } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';
import cartDropdown from '../imports/cartDropdown';
import cartToggle from '../imports/cartToggle';
import Customer from '../imports/Customer';
import Order from '../imports/Order';
import OrderProduct from '../imports/OrderProduct';

const { Base } = window.returns.default;

/**
 * Step3 default page class
 */
class Step3 extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const formSelector = this.options.form || '.personal-info__form';
    const validatorOptions = this.options.validator || {};
    const customerSelector = this.options.customerSelector || '.customer';

    this.validator = new Validator(validatorOptions);
    ajaxDefault();

    cartDropdown();
    let cartQuantity = 0;
    $('.cart__product[data-quantity]').each((index, element) => {
      cartQuantity += Number.parseInt($(element).data('quantity'), 10);
    });
    cartToggle.set(cartQuantity);

    const $customer = $(customerSelector);
    const orderOptions = {
      ...Order.DEFAULTS,
      ...this.options.order,
    };
    const orderProductOptions = {
      ...OrderProduct.DEFAULTS,
      ...orderOptions.product,
    };
    const $order = $(orderOptions.ELEMENT);

    $customer.data('customer', new Customer($customer, this.options));
    const { $currency } = $customer.data('customer');

    $order.data('order', new Order($order, {
      reasons: this.options.reasons,
      commentMaxLength: this.options.commentMaxLength,
      currency: $currency.val(),
    }, orderOptions));

    // Update order products currency
    $currency.on(`change${Customer.NAMESPACE}`, function currencyChange() {
      $order.data('order').setCurrency($(this).val());
    });

    const $error = $('#error_msg');

    const personalInfoFormAPI = $(formSelector).validate({
      // Validate fields in collapsed products too
      ignore: `${customerSelector} :hidden, ${orderOptions.ELEMENT}.hidden :hidden`,

      invalidHandler(event, validator, msg) {
        let $productsToExpand = $();

        // Find collapsed products with invalid fields
        Object.keys(validator.invalid).forEach((fieldName) => {
          const $input = $(`.${orderProductOptions.COLLAPSED} ${orderProductOptions.COLLAPSIBLE} [name="${fieldName}"]`);

          if ($input.length) {
            $productsToExpand = $productsToExpand.add($input.closest(orderProductOptions.PRODUCT));
          }
        });

        $productsToExpand.each((index, element) => {
          $(element).data('product').expand();
        });

        if (msg) {
          $error.html(msg).show();
        } else {
          $error.hide();
        }
      },

      submitHandler(form) {
        const settings = {
          api: personalInfoFormAPI,
        };
        const debugSettings = {
          page: 'step3',
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Step3 } };

export default Step3;
