/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods'; // TODO extract only necessary methods

class Validator {
  constructor(options = {}) {
    // When user enters correct value, script remove text from error label and hide it
    // Because of that fadeOutDown effect looks ugly,
    // So we replace hiding method with empty function
    $.validator.prototype.hideThese = () => {};

    this.options = {
      inputInvalid: Validator.INPUT_INVALID,
      inputValid: Validator.INPUT_VALID,
      // class name for error label
      errorClass: Validator.ERROR_CLASS,
      // class name for error label in case of correct value (triggers fadeOutDown effect)
      validClass: Validator.VALID_CLASS,
      ...options,
    };

    this.setDefaults();
    Validator.additionalMethods();
  }

  setDefaults() {
    const { highlight, unhighlight } = $.validator.defaults;
    const {
      errorClass, validClass, inputInvalid, inputValid,
    } = this.options;

    $.validator.setDefaults({
      // trimming empty spaces from values
      normalizer: value => $.trim(value),
      // class name for error label
      errorClass,
      // class name for error label in case of correct value (triggers fadeOutDown effect)
      validClass,

      // highlighting invalid fields
      highlight(element) {
        // run default method with custom classes
        highlight.call(this, element, inputInvalid, inputValid);
        // show error label with fadeInUp effect
        // TODO: need to test with radios and checkboxes
        $(element).next(`label.${errorClass}`).removeClass(validClass);
      },

      unhighlight(element) {
        // run default method with custom classes
        unhighlight.call(this, element, inputInvalid, inputValid);
        // hide error label with fadeOutDown effect
        // TODO: need to test with radios and checkboxes
        $(element).next(`label.${errorClass}`).addClass(validClass);
      },
    });
  }

  static additionalMethods() {
    // TODO check necessity
    $.validator.addMethod('phone', function phoneValidator(phoneNumber, element) {
      return this.optional(element) || phoneNumber.match(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-.\s\\/]?)?((?:\(?\d+\)?[-.\s\\/]?)*)(?:[-.\s\\/]?(?:#|ext\.?|extension|x)[-.\s\\/]?(\d+))?$/i);
    }, 'Please specify a valid phone number');
  }
}

Validator.INPUT_INVALID = 'input_invalid';
Validator.INPUT_VALID = 'input_valid';
Validator.ERROR_CLASS = 'form__input-error';
Validator.VALID_CLASS = 'form__input-error_valid';

export default Validator;
