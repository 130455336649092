import $ from 'jquery';
import {
  i18n, requireInput, resetFieldError, showField,
} from '../common/helpers';
import Validator from '../common/Validator';

class Customer {
  constructor($element, options = {}) {
    this.$el = $element;
    this.$country = $('#country');
    this.$stateLabel = $('#js-state-label');
    this.$state = $('#state');
    this.$zip = $('#zip');
    this.$currency = $('#currency_code');

    this.$addr3 = $('#addr3');
    this.$neighborhood = $('#neighborhood');
    this.$suburb = $('#suburb');

    this.$customs = $('.js-customs');
    this.$postal_solution = $('.js-postal_solution');
    this.customsData = {};

    this.countryOption = this.$state.find('option:selected');
    this.countryCode = this.countryOption.val();

    this.states = Customer.sortStates(options.states);
    this.required = { ...{}, ...options.countries_requirements };
    this.currencies = { ...{}, ...options.currencies };
    this.fieldSelector = options.fieldSelector;
    this.labelSelector = options.labelSelector;

    this.validatorOptions = {
      inputInvalid: (options.validator && options.validator.inputInvalid) || Validator.INPUT_INVALID,
      errorClass: (options.validator && options.validator.errorClass) || Validator.ERROR_CLASS,
    };

    this.prepareStates();
    this.handleCountryChange();
  }

  /**
   * Sort states for each country in alphabetical order
   * @param {Object} states
   * @return {Object}
   */
  static sortStates(states) {
    const sorted = {};

    Object.keys(states).forEach((countryCode) => {
      sorted[countryCode] = [];

      Object.keys(states[countryCode]).forEach((stateCode) => {
        sorted[countryCode].push({
          code: stateCode,
          text: states[countryCode][stateCode],
        });
      });

      sorted[countryCode].sort((a, b) => {
        if (a.text > b.text) {
          return 1;
        }
        if (a.text < b.text) {
          return -1;
        }
        return 0;
      });
    });

    return sorted;
  }

  /**
   * Prepare state fields
   */
  prepareStates() {
    // Save default state label text and required error text
    this.defaultStateLabel = this.$stateLabel.text();
    this.defaultStateError = this.$state.data('msg-required');

    const stateAttributes = [...this.$state[0].attributes].reduce((acc, attr) => {
      if (attr.name !== 'value' && attr.name !== 'type') {
        acc[attr.name] = attr.value;
      }

      return acc;
    }, {});

    this.$stateDropdown = $('<select>', stateAttributes).addClass('input_select');
    this.$stateInput = this.$state.clone();
  }

  /**
   * Show order, currency dropdown and additional texts for specific countries and specific states
   * Specific states are for Spain ('Las Palmas' and 'Santa Cruz de Tenerife')
   * For Croatia states not specified
   */
  showOrder() {
    const self = this;
    const country = self.$country.find('option:selected').val();
    const data = {
      country,
      state: self.$state.val(),
      zip: self.$zip.val(),
      city: self.$addr3.val(),
      addr1: $('#addr1').val(),
      addr2: $('#addr2').val(),
    };
    let hasCustoms = false;
    let customsBroker = false;
    let forceItemsInfo = false;

    $.ajax({
      type: 'GET',
      url: '/ajax/has_customs',
      noPreloader: true,
      data,
    }).done((response) => {
      try {
        const answer = JSON.parse(response);
        hasCustoms = answer.customs || false;
        customsBroker = answer.customs_broker || false;
        forceItemsInfo = answer.force_items_info || false;
      } catch (e) {
        // console.warn('[showOrder]', e);
      }
    }).always(() => {
      self.$customs.toggleClass('hidden', true);
      self.$postal_solution.toggleClass('hidden', true);
      $('.return-step__action_next').attr('disabled', false);

      if (hasCustoms) {
        // can't create cross-border return w/o customs broker
        if (!customsBroker) {
          // If order info is hidden - reset all error states for products in order
          resetFieldError(self.$customs.find(`.${self.validatorOptions.inputInvalid}`));
          self.$postal_solution.toggleClass('hidden', false);
          $('.return-step__action_next').attr('disabled', true);
        } else {
          self.$customs.toggleClass('hidden', false);
        }
      } else if (forceItemsInfo) {
        self.$customs.toggleClass('hidden', false);
      } else {
        resetFieldError(self.$customs.find(`.${self.validatorOptions.inputInvalid}`));
      }
    });
  }

  /**
   * Automatically set currency value for selected country
   */
  updateCurrency() {
    if (this.currencies) {
      const currency = this.currencies[this.countryCode];

      if (this.$currency.find(`option[value="${currency}"]`).length) {
        this.$currency.val(currency).trigger(`change${Customer.NAMESPACE}`);
      }
    }
  }

  /**
   * Update states field - insert textbox or dropdown with options
   * @param {string} [initial]
   */
  updateStates(initial = '') {
    const states = this.states[this.countryCode];
    // Save previous error state
    const isInvalid = this.$state.hasClass(this.validatorOptions.inputInvalid);

    if (states) {
      // If the country has states, then create list of options and add it to the dropdown
      this.$stateDropdown.empty().append(this.getStatesList(states));
      this.$state.replaceWith(this.$stateDropdown);

      if (this.$state.is('input')) {
        // If dropdown is replacing simple textbox, then event handler have to be attached again
        // because of $.replaceWith() removes all attached event handlers
        this.$stateDropdown.on(`change${Customer.NAMESPACE}`, () => this.showOrder());
      }

      // Save link to inserted state dropdown
      this.$state = this.$stateDropdown;

      const $selected = this.$stateDropdown.find(`option[value="${initial}"], option:contains(${initial})`).first();
      if ($selected.length) {
        // Set state value if it was present on page load
        $selected.prop('selected', true);
        this.$stateDropdown.trigger(`change${Customer.NAMESPACE}`);
      }
    } else {
      // If the country has no states
      this.$state.replaceWith(this.$stateInput);
      // Save link to inserted state textbox and reset it's value while country changes
      this.$state = this.$stateInput.val(initial);
    }

    // Repeat error state
    this.$state.toggleClass(this.validatorOptions.inputInvalid, isInvalid);
  }

  /**
   * Create jQuery collection of options for states dropdown
   * @param {Array} states
   * @return {jQuery}
   */
  getStatesList(states) {
    let $options = $(`<option value="" selected>${i18n('Not selected')}</option>`);

    states.forEach((state) => {
      $options = $options.add(`<option value="${state.code}">${state.text}</option>`);
    });

    return $options;
  }

  /**
   * Update label text and error message for state field
   * Currently 'District' for Hong Kong and 'Emirate' for United Arab Emirates
   */
  updateStateLabel() {
    const newStateLabel = this.countryOption.data('state') || this.defaultStateLabel;
    const newStateError = this.$state.data(`msg-required-${this.countryCode.toLowerCase()}`) || this.defaultStateError;

    if (this.$stateLabel.text() !== newStateLabel) {
      this.$stateLabel.text(newStateLabel);
      this.$state.data('msg-required', newStateError).next(`.${this.validatorOptions.errorClass}`).text(newStateError);
    }
  }

  /**
   * Toggle visibility of city, neighborhood and suburb for specific countries
   */
  updateVisible() {
    // NB non-obvious behavior - using not_required for hiding fields
    // Refactoring. Using required (1|0) logic instead of not_required (1|null)
    showField(this.$addr3, this.required.city[this.countryCode] === '1', this.fieldSelector);
    // Show neighborhood for Mexico (countryCode = MX)
    const showNeighborhood = this.countryOption.data('neighborhood');
    showField(this.$neighborhood, showNeighborhood, this.fieldSelector);
    // Add additional class to country field group for proper styling
    // NB: Second parameter for $.toggleClass() have to be Boolean
    this.$country.closest('.form__group').toggleClass('customer__group_country_neighborhood', !!showNeighborhood);

    // Show suburb for Australia (countryCode = AU)
    showField(this.$suburb, this.countryOption.data('suburb'), this.fieldSelector);
  }

  /**
   * Update required state for zip and state for specific countries
   */
  updateRequired() {
    requireInput(this.$zip, this.required.zip[this.countryCode] === '1', this.labelSelector);
    requireInput(this.$state, this.required.state[this.countryCode] === '1', this.labelSelector);
  }

  /**
   * Change customer form appearance while country changes
   */
  handleCountryChange() {
    const self = this;

    this.$country.on(`change${Customer.NAMESPACE}`, function countryChange(event, initial) {
      self.countryOption = $(this).find('option:selected');
      self.countryCode = self.countryOption.val();

      self.showOrder();
      self.updateCurrency();
      self.updateStateLabel();
      self.updateStates(initial);
      self.updateVisible();
      self.updateRequired();
    }).trigger(`change${Customer.NAMESPACE}`, [this.$state.val()]);
  }
}

Customer.NAMESPACE = '.customer';

export default Customer;
