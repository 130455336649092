/* eslint-disable class-methods-use-this */

import objectFitImages from 'object-fit-images';

const { Base } = window.returns.default;

/**
 * Landing default page class
 */
class Landing extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    objectFitImages();
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Landing } };

export default Landing;
