/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import objectFitImages from 'object-fit-images'; // object-fit polyfill
import 'jquery.mmenu/dist/jquery.mmenu.all'; // TODO Use custom build instead of full script
import { ajaxSettings } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';
import cartDropdown from '../imports/cartDropdown';
import Cart from '../imports/Cart';
import Gallery from '../imports/Gallery';
import SelectionProduct from '../imports/SelectionProduct';
import Popup from '../imports/Popup';

const { Base } = window.returns.default;

/**
 * Step2 default page class
 */
class Step2 extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const formSelector = this.options.form || '.selection__form';
    const validatorOptions = this.options.validator || {};
    const { selector: lazySelector = Gallery.IMAGE, shownClass } = this.options.lazyLoad || {};
    const selectionOptions = this.options.selection || {};

    objectFitImages();
    this.validator = new Validator(validatorOptions);
    ajaxDefault();
    cartDropdown();

    const $cart = $('#cart');
    $cart.data('cart', new Cart($cart));

    // Images lazy load
    let figureRatio;
    try {
      const figureStyles = getComputedStyle(document.querySelector('.product-card__figure'));
      figureRatio = parseInt(figureStyles.getPropertyValue('width')) / parseInt(figureStyles.getPropertyValue('height'));
    } catch (e) {}
    $(lazySelector).each((index, element) => Gallery.load($(element), shownClass, figureRatio));

    $(selectionOptions.product || SelectionProduct.PRODUCT).each((index, element) => {
      const $product = $(element);
      $product.on('selected', (event, state, data) => {
        if ($cart.length) {
          if (state) {
            $cart.data('cart').addProduct(data);
          } else {
            $cart.data('cart').removeProduct(data);
          }
        }
      }).data('product', new SelectionProduct($product, this.options.reasons, this.options.commentMaxLength, selectionOptions));
    });

    const $popup = $('#reason-popup');
    $popup.data('popup', new Popup($popup, this.options.reasons, this.options.commentMaxLength));

    const $error = $('#error_msg');

    const selectionFormAPI = $(formSelector).validate({
      invalidHandler(event, validator, message, target) {
        const $target = (typeof target === 'string') ? $(target) : $error;

        if (message) {
          $target.html(message).show();
        } else {
          $target.hide();
        }
      },

      submitHandler(form) {
        const settings = {
          api: selectionFormAPI,
        };
        const debugSettings = {
          page: 'step2',
          actions: ['check'],
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Step2 } };

export default Step2;
