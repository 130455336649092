/* eslint-disable class-methods-use-this */

import $ from 'jquery';

class Option {
  constructor($element, ...params) {
    this.$el = $element;
    this.$radio = this.$el.find(this.constructor.RADIO);

    this.selected = this.$el.hasClass(this.constructor.ITEM_SELECTED);
    this.disabled = this.$el.hasClass(this.constructor.ITEM_DISABLED);
    this.radioName = `__${this.constructor.name}`;

    this.init(...params);
    this.checkRadioPresence();
    this.update();
    this.handleEvents();
  }

  init() {}

  checkRadioPresence() {
    if (this.$radio.length === 0) {
      this.$radio = $('<input>', {
        class: 'radio__input',
        name: this.radioName,
        type: 'radio',
        checked: this.selected,
        disabled: this.disabled,
      }).appendTo(this.$el);
    }
  }

  toggleDisability(toggle) {
    if (toggle === this.disabled) {
      return;
    }

    this.$el.toggleClass(this.constructor.ITEM_DISABLED, toggle);
    this.$radio.prop('disabled', toggle);
    this.disabled = toggle;
  }

  disable() {
    this.toggleDisability(true);
  }

  enable() {
    this.toggleDisability(false);
  }

  toggleSelection(toggle) {
    if (toggle === this.selected) {
      // State wasn't changed
      return false;
    }

    this.$el.toggleClass(this.constructor.ITEM_SELECTED, toggle);
    this.$radio.prop('checked', toggle);
    this.selected = toggle;

    // State was changed
    return true;
  }

  select() {
    if (this.toggleSelection(true)) {
      // If state was changed - trigger event
      this.$radio.trigger(`change${this.constructor.NAMESPACE}`);
    }
  }

  deselect() {
    if (this.toggleSelection(false)) {
      // If state was changed - trigger event
      this.$radio.trigger(`change${this.constructor.NAMESPACE}`);
    }
  }

  update() {
    this.toggleDisability(this.$radio.prop('disabled'));
    this.toggleSelection(this.$radio.prop('checked'));
  }

  handleRadioEvents() {
    const self = this;

    this.$radio.on({
      [`change${self.constructor.NAMESPACE}`]() {
        // update all radios with the same name attribute
        $(`${self.constructor.RADIO}[name="${$(this).attr('name')}"]`).trigger(`update${self.constructor.NAMESPACE}`);
      },
      [`click${self.constructor.NAMESPACE}`](e) {
        // prevent click handling attached to this.$el
        e.stopPropagation();
      },
      [`update${self.constructor.NAMESPACE}`]() {
        // update courier state
        self.update();
      },
    });
  }

  handleElementEvents() {
    const self = this;

    this.$el.on(`click${self.constructor.NAMESPACE}`, () => {
      // TODO test in all browsers
      // change radio button state
      self.$radio.trigger(`click${self.constructor.NAMESPACE}`);
    });
  }

  handleEvents() {
    this.handleRadioEvents();
    this.handleElementEvents();
  }
}

const NAME = 'option';

Option.ITEM = `.${NAME}`;
Option.ITEM_SELECTED = `${NAME}_selected`;
Option.ITEM_DISABLED = `${NAME}_disabled`;
Option.RADIO = '.radio__input';
Option.NAMESPACE = `.${NAME}`;

export default Option;
