/* eslint-disable class-methods-use-this */
/* global adyen */

import $ from 'jquery';
import { ajaxSettings, i18n } from '../common/helpers';
import Validator from '../common/Validator';
import ajaxDefault from '../imports/ajaxDefaults';
import cartDropdown from '../imports/cartDropdown';
import cartToggle from '../imports/cartToggle';
import PaymentType from '../imports/Payment';
import itemsSummary from '../imports/itemsSummary';

const { Base } = window.returns.default;

/**
 * Payment default page class
 */
class Payment extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const validatorOptions = this.options.validator || {};

    this.validator = new Validator(validatorOptions);
    ajaxDefault({
      adyen_handle_action(additionalActionData) {
        renderThreeDSForm(additionalActionData);

        $('html, body').animate({
          scrollTop: $('#additional-action-container').closest('.payment__info').offset().top - 5,
        }, 1500);
      },
      restore_next_btn() {
        // there is no need to enable "next" button using assets
        // but this callback needed for backward compatibility with previous assets versions.
      },
    });

    itemsSummary();

    const preloader = $('.preloader').data('preloader');

    let adyenV2Checkout;
    let adyenV2State;
    let card;

    cartDropdown();
    let cartQuantity = 0;
    $('.cart__product:not(.cart__product_method)').each((index, element) => {
      cartQuantity += Number.parseInt($(element).data('quantity'), 10);
    });
    cartToggle.set(cartQuantity);

    const $error = $('#error_msg');

    let payment;

    $(PaymentType.ITEM).each((index, element) => {
      const $type = $(element);
      $type.data('payment', new PaymentType($type));
    }).on('change', function paymentChange() {
      $error.hide();
      payment = $(this).find(PaymentType.RADIO).val();

      $(`.payment__details:not([data-type="${payment}"])`).addClass('hidden');
      $(`.payment__details[data-type="${payment}"]`).removeClass('hidden');
    });

    const $paymentForm = $('.payment__form');
    const $adyen = $('.js-adyen-credit-card');

    if ($adyen.length) {
      adyen.createEncryptedForm($paymentForm[0], {
        name: 'adyen_encrypted',
      });
    }

    const $adyenV2 = $('.js-adyen-v2-credit-card');

    if ($adyenV2.length) {
      var checkBox = document.getElementById("usePersonalInfoAsBillingAddress");
      if (checkBox) {
        checkBox.addEventListener('click', function (e) {
          var target = e.target;
          if (target.checked) {
            card.update(
              {
                data: {
                  holderName: holderName,
                  billingAddress: {
                    country: country,
                    street: street,
                    postalCode: postalCode,
                    city: city,
                    state: state,
                    houseNumberOrName: houseNumber
                  }
                }
              }
            );

            target.parentElement.style.display = 'none';
          }
        }, false);
      }

      const configuration = {
        paymentMethodsResponse: paymentMethods,
        locale: customerLocale,
        clientKey: adyenClientKey,
        environment: adyenEnvironment,
        type: 'card',
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: true,
        brands: cardTypes,
        onChange: function(state, component) {
          adyenV2State = state;
        },
        onSubmit: function(state) {
          return false;
        },
        onAdditionalDetails: function (state, component) {
          $.ajax(
            ajaxSettings(
              {
                url: '/payments/accept_adyen',
                type: 'POST',
                data: state.data,
                dataType: 'json'
              },
              {
                page: 'payment',
              }
            ),
          ).done((result) => {
            preloader.focus();
            if (result.redirect && result.redirect.length > 0) {
              //timeout need for safari animation
              setTimeout(function () {
                location.href = result.redirect;
              }, 50);
            }

            if (result.action) {
              renderThreeDSForm(result.action);
            }
          }).fail(() => {
            preloader.focus();
            show_error_box({
              'auto_close': 5,
              'message': 'Server error. Please try again later'
            });
          });
        }
      };

      if (useBillingAddressFromSession) {
        configuration.data =  {
          holderName: holderName,
          billingAddress: {
            country: country,
            street: street,
            postalCode: postalCode,
            city: city,
            state: state,
            houseNumberOrName: houseNumber
          }
        };
      }

      adyenV2Checkout = new AdyenCheckout(configuration);

      if (additionalActionData !== null) {
        renderThreeDSForm(additionalActionData);
      }

      card = adyenV2Checkout.create('card').mount('#component-container')
    }

    const $voucherForm = $('.voucher__form');
    const $mockVoucher = $('#voucher_number_mock');
    let mockValidatorOptions = {};

    if ($mockVoucher.length) {
      $mockVoucher.on('change input', (e) => {
        $('#voucher_number').val($(e.target).val());
        $voucherForm.valid();
      });
      $('#voucher-apply').on('click', (e) => {
        e.preventDefault();
        $voucherForm.submit();
      });

      const classes = {
        inputInvalid: Validator.INPUT_INVALID,
        errorClass: Validator.ERROR_CLASS,
        validClass: Validator.VALID_CLASS,
        ...validatorOptions,
      };
      mockValidatorOptions = {
        ignore: '',
        highlight() {
          $mockVoucher.addClass(classes.inputInvalid).next(`label.${classes.errorClass}`).removeClass(classes.validClass);
        },
        unhighlight() {
          $mockVoucher.removeClass(classes.inputInvalid).next(`label.${classes.errorClass}`).addClass(classes.validClass);
        },
      };
    }

    const voucherFormAPI = $voucherForm.validate({
      ...mockValidatorOptions,
      invalidHandler(event, validator, msg) {
        if (msg) {
          $error.html(msg).show();
        } else {
          $error.hide();
        }
      },

      submitHandler(form) {
        const settings = {
          api: voucherFormAPI,
        };
        const debugSettings = {
          page: 'voucher',
        };

        $(form).ajaxSubmit(ajaxSettings(settings, debugSettings));
      },
    });

    const paymentFormAPI = $paymentForm.validate({
      ignore: ':hidden',
      invalidHandler(event, validator, msg) {
        if (msg) {
          $error.html(msg).show();
        } else {
          $error.hide();
        }
      },

      errorPlacement(error, element) {
        if (element.is(':radio')) {
          $error.text(error.text()).show();
        } else {
          element.after(error);
        }
      },

      submitHandler(form) {
        const $form = $(form);
        const settings = {
          api: paymentFormAPI,
        };
        const debugSettings = {
          page: 'payment',
        };

        if (payment === 'adyen_v2') {
          card.submit();
        }

        if (payment === 'adyen_v2' && adyenV2State !== 'undefined' && adyenV2State.isValid) {
          $("<input />").attr("type", "hidden")
              .attr("name", "type")
              .attr("value", adyenV2State.data.paymentMethod.type)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "encryptedCardNumber")
              .attr("value", adyenV2State.data.paymentMethod.encryptedCardNumber)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "encryptedExpiryMonth")
              .attr("value", adyenV2State.data.paymentMethod.encryptedExpiryMonth)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "encryptedExpiryYear")
              .attr("value", adyenV2State.data.paymentMethod.encryptedExpiryYear)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "encryptedSecurityCode")
              .attr("value", adyenV2State.data.paymentMethod.encryptedSecurityCode)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "holderName")
              .attr("value", adyenV2State.data.paymentMethod.holderName)
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "browserInfo")
              .attr("value", JSON.stringify(adyenV2State.data.browserInfo))
              .appendTo($form);
          $("<input />").attr("type", "hidden")
              .attr("name", "billingAddress")
              .attr("value", JSON.stringify(adyenV2State.data.billingAddress))
              .appendTo($form);
        }

        if (payment === 'adyen') {
          const cardData = {
            number: $('#adyen-card-number').val(),
            holderName: $('#adyen-card-name').val(),
            expiryMonth: $('#adyen-card-month').val(),
            expiryYear: $('#adyen-card-year').val(),
            cvc: $('#adyen-card-cvc').val(),
            generationtime: $('#adyen-generation-time').val(),
          };
          const cseInstance = adyen.createEncryption();
          const encriptedData = cseInstance.encrypt(cardData);

          if (encriptedData !== false) {
            $('#adyen-encrypted-data').val(encriptedData);
          } else {
            $error.text(i18n('Please check you have entered your card details correctly.')).show();
          }
        }
        if (adyenV2State === 'undefined' || adyenV2State.isValid) {
          $form.ajaxSubmit(ajaxSettings(settings, debugSettings));
        }
      },
    });

    function renderThreeDSForm(threeDsData)
    {
      var threeDsConfiguration = {
        size: '05',
      };

      return adyenV2Checkout.createFromAction(threeDsData, threeDsConfiguration).mount('#additional-action-container');
    }
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Payment } };

export default Payment;
