import $ from 'jquery';
import 'jquery.mmenu/dist/jquery.mmenu.all'; // TODO Use custom build instead of full script
import { checkScrollBar, floatBreakpoint, getScrollbar } from '../common/helpers';

export default function () {
  const $body = $('body');
  const direction = $('html').attr('dir');

  const scrollbarWidth = getScrollbar();
  const $cartWrapper = $('.cart__wrapper');

  if (!$cartWrapper.length) {
    return;
  }

  const types = ['mobile', 'desktop'];
  const $cart = {};
  const $cartToggle = {};
  const api = {};

  function getOptions(type) {
    return {
      extensions: ['pagedim-black'],

      hooks: {
        'open:before': () => {
          $cart[type].find('.mm-panels').append($cartWrapper);

          if (checkScrollBar()) {
            $body.css('padding-right', scrollbarWidth);
          }
        },

        'open:start': () => {
          $cartToggle[type].attr('aria-expanded', true);
        },

        'close:start': () => {
          $cartToggle[type].attr('aria-expanded', false);
        },

        'close:finish': () => {
          $body.css('padding-right', '');
        },
      },

      navbar: false,

      offCanvas: {
        blockUI: true,
      },

      dropdown: {
        drop: true,

        position: {
          of: `.cart-toggle_type_${type}`,
          x: (direction === 'ltr') ? 'right' : 'left',
          y: 'bottom',
        },
      },

      autoHeight: true,

      onClick: {
        setSelected: false,
      },
    };
  }

  const config = {
    dropdown: {
      offset: {
        button: {
          x: (direction === 'ltr') ? -18 : -11,
        },
      },

      width: {
        max: 360,
      },
    },
  };

  types.forEach((type) => {
    $cart[type] = $(`#cart-${type}`);
    $cartToggle[type] = $(`.cart-toggle_type_${type}`);

    // Initialize mmenu
    api[type] = $cart[type].mmenu(getOptions(type), config).data('mmenu');

    $cartToggle[type].on('click.cart', function toggleClick() {
      if ($(this).hasClass('cart-toggle_active')) {
        api[type].open();
      }
    });
  });

  floatBreakpoint('before', () => api.desktop.close());
  floatBreakpoint('after', () => api.mobile.close());
}
