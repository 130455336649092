/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import { log, warn } from '../common/helpers';
import mainMenu from './mainMenu';
import languageMenu from './languageMenu';
// import pageHeader from './pageHeader';
import preloader from './Preloader';

/**
 * Core page class
 */
class Base {
  /**
   * Class constructor
   */
  constructor(options = {}) {
    // Prevent multiple initialization
    if (window.returns.initialized) {
      warn('Initialized previously');
      return;
    }

    window.returns.initialized = true;
    log(`${this.constructor.name} is being initialized`);

    this.options = { ...options, ...window.returns.options };
    this.preloader = preloader;

    this.common();
    this.init();

    if (this.options.afterInit) {
      this.options.afterInit.call(this);
    }
  }

  /**
   * Common scripts for all pages
   */
  common() {
    mainMenu();
    languageMenu();
    // pageHeader();

    // Do not show preloader when user:
    // - clicks anchors without 'href' attribute
    // - clicks anchors with hash in 'href' attribute
    // - clicks anchors with '.js-no-preload' class
    // - clicks external links
    // - uses Samsung Internet Browser
    // TODO check it
    const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
    if (!isSamsungBrowser) {
      // If Samsung Internet Browser user moving back, preloader is visible and blocking content
      // So for these users preloader is disabled
      $('a[href]:not([href^="#"]):not(.js-no-preload)').not(`a[href*="//"]:not([href*="${window.location.hostname}"])`).on('click', () => preloader.blur());
    }
  }

  /**
   * Main functionality of the page
   */
  init() {}
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Base } };

export default Base;
