import $ from 'jquery';
import { isNatural } from '../common/helpers';

const cartToggle = {
  $el: $('.cart-toggle'),
  $qty: $('.cart-toggle__quantity'),

  classActive: 'cart-toggle_active',
  classPulse: 'cart-toggle_pulse',

  /**
   * @param {number} value
   * @returns {cartToggle}
   */
  set(value) {
    const valueToSet = isNatural(value) ? value : 0;
    cartToggle.$el.toggleClass(cartToggle.classActive, !!valueToSet);
    cartToggle.$qty.text(valueToSet || '');
    return this;
  },

  /**
   * @returns {cartToggle}
   */
  pulse() {
    // TODO need to test with multiple fired events
    // https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/#detecting-and-executing-when-transitions-end-with-jquery
    const events = [
      'animationend',
      'webkitAnimationEnd',
      'MSAnimationEnd',
      'oAnimationEnd',
    ];
    cartToggle.$el
      .addClass(cartToggle.classPulse)
      .one(events.join(' '), () => {
        cartToggle.$el.removeClass(cartToggle.classPulse);
      });
    return this;
  },
};

export default cartToggle;
