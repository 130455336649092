import $ from 'jquery';
import Reason from './Reason';

/**
 * Product class
 */
class SelectionProduct {
  /**
   * Class constructor
   * @param {jQuery} $element
   * @param {Object} reasons
   * @param {number} commentMaxLength
   * @param {Object} options
   */
  constructor($element, reasons, commentMaxLength, options) {
    this.options = {
      product: SelectionProduct.PRODUCT,
      selected: SelectionProduct.SELECTED,
      button: SelectionProduct.BUTTON,
      label: SelectionProduct.LABEL,
      ...options,
    };

    this.$el = $element;
    this.$button = this.$el.find(this.options.button);
    this.$image = this.$el.find(SelectionProduct.IMAGE);
    this.$label = this.$el.find(this.options.label);

    this.fillData();

    this.$quantity = this.$el.find('.js-quantity');
    this.$quantityShown = this.$el.find('.js-quantity-shown');
    this.reasonsGlobal = reasons;
    this.commentMaxLength = commentMaxLength;
    this.createReasonInputs();

    this.selected = this.$el.hasClass(this.options.selected);
    this.disabled = this.$button.prop('disabled');

    this.available = Number.parseInt(this.$el.data('available'), 10) || 0;

    this.updateButton();
    this.handleClick();
  }

  createReasonInputs(newReasons) {
    this.deselect();

    const template = $('#selection__reasons-template').html();
    const $reasonsWrap = this.$el.find('.selection__reasons').empty();

    if (newReasons) {
      this.$quantity.data('reasons', newReasons);
    }

    this.reasons = this.$quantity.data('reasons') || [];
    this.$quantity.val(this.reasons.length);
    this.$quantityShown.text(this.reasons.length).parent().toggleClass('hidden', !this.reasons.length);
    this.quantity = this.reasons.length;

    if (this.reasons.length) {
      this.reasons.forEach((itemReasons, index) => {
        const $panel = $(template).data('reasons', itemReasons).appendTo($reasonsWrap);
        $panel.data('reason', new Reason($panel, {
          name: `item_returned_reasons_additional[${this.id}]`,
          index,
          reasons: this.reasonsGlobal,
          commentMaxLength: this.commentMaxLength,
          isHidden: true,
        }));
      });

      this.select();
    }
  }

  fillData() {
    this.id = this.$el.data('id');
    this.title = this.$el.find('.js-title').text();
    this.code = this.$el.find('.js-code').text();
    this.size = this.$el.find('.js-size').text();
    this.price = Number.parseFloat(this.$el.find('.js-price').text()) || 0;
  }

  getData() {
    return {
      id: this.id,
      title: this.title,
      code: this.code,
      size: this.size,
      price: this.price,
      reasons: this.reasons,
    };
  }

  /**
   * Update button state
   * @param {boolean} [isInit]
   */
  updateButton(isInit) {
    if (!isInit) {
      this.$el.toggleClass(this.options.selected, this.selected);
    }

    this.$label.each((_, el) => {
      let label;

      if (this.disabled) {
        label = 'disabled';
      } else if (this.selected) {
        label = 'deselect';
      } else {
        label = 'select';
      }

      $(el).text($(el).data(label));
    });
  }

  /**
   * Whether the product is selected
   * @returns {boolean}
   */
  isSelected() {
    return this.selected;
  }

  /**
   * Whether the product is disabled
   * @returns {boolean}
   */
  isDisabled() {
    return this.disabled;
  }

  /**
   * Select product
   */
  select() {
    this.selected = true;
    this.updateButton();
    this.$el.trigger('selected', [true, this.getData()]);
  }

  /**
   * Deselect product
   */
  deselect() {
    // NB set qty as 0 and remove hidden inputs here
    this.selected = false;
    this.updateButton();
    this.quantity = 0;
    this.$quantity.val(this.quantity);
    this.$quantityShown.parent().addClass('hidden');
    this.$el.trigger('selected', [false, this.getData()]);
  }

  /**
   * Show modal window with reasons for return
   */
  ask() {
    const popup = $('#reason-popup').data('popup');
    popup.open(this);
  }

  /**
   * Handle product button click
   */
  handleClick() {
    this.$button.add(this.$image).on(`click${SelectionProduct.NAMESPACE}`, (e) => {
      e.preventDefault();
      if (!this.disabled) {
        this.ask();
      }
    });
  }
}

SelectionProduct.PRODUCT = '.selection__product';
SelectionProduct.SELECTED = 'selection__product_selected';
SelectionProduct.BUTTON = '.selection__button';
SelectionProduct.IMAGE = '.selection__image';
SelectionProduct.LABEL = '.selection__label';
SelectionProduct.NAMESPACE = '.product';

export default SelectionProduct;
