export default () => {
    $('.items-summary_accordion .items-summary__header_toggle').on('click', function () {
        $(this).closest('.items-summary_accordion').toggleClass('items-summary_accordion_open');
        $(this).next('.items-summary__wrapper').slideToggle({
            duration: 150,
            start: function () {
                $(this).css('display', 'flex');
            }
        });
    });
};
