// jQuery and $.validation is globally scoped
// So for decreasing theme script we don't import them here

/* global $ */
const Validator = {}; // From ./Validator.js
Validator.INPUT_INVALID = 'input_invalid';
Validator.ERROR_CLASS = 'form__input-error';
// TODO next two line breaks the prev themes logic, need to clear the code
Validator.INPUT_INVALID = 'input_state_invalid';
Validator.ERROR_CLASS = 'field__error';

window.returns = { ...window.returns };

/**
 * Check is it a production, develop or local build
 * @returns {boolean|string}
 */
export function isDev() {
  return window.localStorage && localStorage.getItem('returns-dev-mode') === '1';
}

/**
 * Logging
 * @param {*} message
 * @param {string} [level = 'log']
 */
export function log(message, level = 'log') {
  if (isDev() && window.console) {
    console[level]('[returns]', message); // eslint-disable-line no-console
  }
}

/**
 * Warning
 * @param message
 */
export function warn(message) {
  return log(message, 'warn');
}

/**
 * Internationalization
 * @param {string} key
 * @returns {string}
 */
export function i18n(key) {
  const strings = { ...{}, ...window.returns.i18n };

  if (typeof strings[key] === 'undefined') {
    warn(`i18n warning: Unknown string '${key}'`);
    return key;
  }

  return strings[key];
}

/**
 * Check is number natural
 * @param {*} value
 * @returns {boolean}
 */
export function isNatural(value) {
  return Number.isInteger(value) && value > 0;
}

/**
 * Check is number natural with zero
 * @param {*} value
 * @returns {boolean}
 */
export function isNaturalZero(value) {
  return isNatural(value) || value === 0;
}

/**
 * Throttling
 * @param {Function} func
 * @param {number} ms
 * @returns {Function}
 * @see https://learn.javascript.ru/task/throttle
 */
export function throttle(func, ms = 200) {
  let isThrottled = false;
  let savedArgs;

  function wrapper(...args) {
    if (isThrottled) {
      savedArgs = args;
      return;
    }

    func.apply(this, args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(this, savedArgs);
        savedArgs = null;
      }
    }, ms);
  }

  return wrapper;
}

export function resetFieldError($input) {
  $input.removeClass(Validator.INPUT_INVALID).next(`.${Validator.ERROR_CLASS}`).remove();
}

/**
 * @param {jQuery} $input
 * @param {boolean} isVisible
 * @param {string} parent
 */
export function showField($input, isVisible, parent = '.form__group') {
  $input.closest(parent).toggleClass('hidden', !isVisible);

  if (!isVisible && $input.hasClass(Validator.INPUT_INVALID)) {
    // If the field was invalid and hided, then remove it's error state
    resetFieldError($input);
  }
}

export function requireInput($input, isRequired, labelSelector = '.form__label') {
  // Set 'required' attribute
  $input.prop('required', isRequired);

  // Toggle asterisk visibility and add it if necessary
  const $label = $(`${labelSelector}[for="${$input.attr('id')}"]`);
  let $asterisk = $label.find('.asterisk');
  if (!$asterisk.length) {
    $asterisk = $('<span>', { class: 'asterisk' }).appendTo($label);
  }
  $asterisk.toggleClass('hidden', !isRequired);

  if ($input.is('select')) {
    // If the input field is dropdown, then toggle the availability of placeholder option
    $input.find('option:not([value])').prop('disabled', isRequired);
  }

  if (!isRequired) {
    // If field is not required, then remove it's error state
    // TODO if error caused not due to requirement, then error state have to be leaved
    resetFieldError($input);
  }
}

export function formatPrice(price) {
  let float = Number.parseFloat(price);

  if (Number.isNaN(float)) {
    float = 0;
  }

  return float.toFixed(2);
}

// From Bootstrap modal.js
export function getScrollbar() {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('style', 'position:absolute; top:-9999px; width:50px; height:50px; overflow:scroll;');
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
}

// From Bootstrap modal.js
export function checkScrollBar() {
  const rect = document.body.getBoundingClientRect();
  return rect.left + rect.right < window.innerWidth;
}

export function parseTemplate(template, data = {}) {
  let html = template;

  Object.keys(data).forEach((key) => {
    // If string is stated as false, then just remove the placeholder
    html = html.replace(new RegExp(`%${key}%`, 'g'), (data[key] !== false) ? data[key] : '');
  });

  return html;
}

/**
 * @param {string} template Template HTML
 * @param {Array} fields Array of all placeholders in the template
 * @param {Object} data Object with data, passed to the template
 * @return {string}
 */
export function parseTemplate2(template, fields = [], data = {}) {
  const fullData = {};

  // Iterate over all possible placeholders in the template and remove those has no data
  fields.forEach((field) => {
    fullData[field] = false;
  });

  return parseTemplate(template, { ...fullData, ...data });
}

export function floatBreakpoint(dir = 'before', callback = () => {}, breakpoint = 992) {
  // NB: breakpoint default value is equal to $float-breakpoint-up variable
  // from scss/imports/_variables.scss
  const query = dir === 'after' ? `(min-width: ${breakpoint}px)` : `(max-width: ${breakpoint - 0.02}px)`;

  window.matchMedia(query).addListener((mq) => {
    if (mq.matches) {
      callback();
    }
  });
}

export function ajaxSettings(settings, { page, actions = [], noNext = false }) {
  const newSettings = { ...settings };

  if (typeof newSettings.method === 'undefined') {
    // Set method for all requests if not explicitly specified
    newSettings.method = 'POST';
  }

  // Settings for debug
  if (window.localStorage && localStorage.getItem('mock') === 'true') {
    window.urlIndex = window.urlIndex || 0;
    const urls = noNext ? actions : [...actions, 'next'];
    newSettings.method = 'GET';
    newSettings.url = `./form/${page}-${urls[window.urlIndex++ % urls.length]}.json`; // eslint-disable-line
  }

  return newSettings;
}
