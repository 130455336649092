import $ from 'jquery';
import 'jquery.mmenu/dist/jquery.mmenu.all'; // TODO Use custom build instead of full script
import { floatBreakpoint } from '../common/helpers';

export default function () {
  const $html = $('html');
  const $mainMenu = $('.drawer__nav');

  if (!$mainMenu.length) {
    return;
  }

  const $menuToggle = $('.page__header-hamburger');

  const activeToggleClass = 'is-active';

  const api = $mainMenu.mmenu({
    extensions: [
      'fx-menu-slide',
      'pagedim-black',
      `position-${($html.attr('dir') === 'ltr') ? 'right' : 'left'}`,
    ],
    hooks: {
      'open:start': () => {
        $menuToggle.attr('aria-expanded', true);
      },
      'close:start': () => {
        $menuToggle.attr('aria-expanded', false);
      },
    },
    navbar: {
      add: false,
    },
  }, {
    transitionDuration: 400,
  }).data('mmenu');

  $menuToggle.on('click', function clickMenuToggle() {
    if ($(this).hasClass(activeToggleClass)) {
      api.close();
    } else {
      api.open();
    }
  });

  $('.js-drawer-close').on('click', (e) => {
    e.preventDefault();
    api.close();
  });

  floatBreakpoint('after', () => api.close(), 768);
}
