/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import Accordion from '../imports/Accordion';

const { Base } = window.returns.default;

/**
 * Help default page class
 */
class Help extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const $accordion = $('.accordion');
    $accordion.data('accordion', new Accordion($accordion));
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Help } };

export default Help;
