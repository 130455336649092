/* eslint-disable class-methods-use-this */

import $ from 'jquery';
import tippy from 'tippy.js';
import Gallery from '../imports/Gallery';

const { Base } = window.returns.default;

/**
 * Default page class
 */
class Label extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    const $copy = $('.confirmation__copy');
    $copy.on('click', () => {
      const $temp = $('<input />');
      $('body').append($temp);
      $temp.val($('.js-tracking-number').text()).select();

      let content = '';
      try {
        document.execCommand('copy');
        content = $copy.data('success');
      } catch (err) {
        content = $copy.data('error');
      }

      tippy('.confirmation__copy', {
        content,
        onHidden(instance) {
          instance.destroy();
        },
      })[0].show();

      $temp.remove();
    });

    // Barcodes lazy load
    $(Gallery.IMAGE).each((index, element) => {
      const $image = $(element);

      Gallery.load($image);
    });
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Label } };

export default Label;
