import $ from 'jquery';
import ajaxForm from 'jquery-form';
import { isDev, warn } from '../common/helpers';

export default function (callbacks = {}) {
  ajaxForm(window, $);
  $.fn.ajaxSubmit.debug = isDev();
  const preloader = $('.preloader').data('preloader');

  $(document)
    .ajaxSend((event, jqxhr, settings) => {
      if (!settings.noPreloader && preloader) {
        preloader.blur();
      }
    })
    .ajaxSuccess((event, jqxhr, settings) => {
      let result;

      try {
        result = jqxhr.responseJSON || JSON.parse(jqxhr.responseText);
      } catch (err) {
        result = { error: true, msg: 'Wrong response. Try again later' };
      }

      const validator = settings.api;

      if (!validator) {
        return;
      }

      const form = validator.currentForm;
      if (preloader) {
        preloader.focus();
      }
      if (typeof result.callbacks === 'string' && result.callbacks.length > 0) {
        const functions = result.callbacks.split(',');

        functions.forEach((func) => {
          const funcTrimmed = func.trim();

          if (callbacks[funcTrimmed] !== undefined) {
            if (result.data !== undefined && result.data) {
              callbacks[funcTrimmed](result.data);
            } else {
              callbacks[funcTrimmed]();
            }
          } else {
            warn(`Unknown callback '${funcTrimmed}'`);
          }
        });
      }

      if (result.error) {
        $(form).trigger('invalid-form.validate', [validator, result.msg]);
      } else if (typeof result.redirect === 'string' && result.redirect.length > 0) {
        // Timeout is needed for Safari animation TODO: check is it necessary
        setTimeout(() => {
          window.location.href = result.redirect;
        }, 51);
      }
    })
    .ajaxError((event, jqxhr, settings, thrownError) => {
      if (preloader) {
        preloader.focus();
      }

      const validator = settings.api;

      if (!validator) {
        warn(`Something went wrong. Please, try again later.<br> Error: ${thrownError}`);
        return;
      }

      const form = validator.currentForm;
      $(form).trigger('invalid-form.validate', [validator, `Something went wrong. Please, try again later.<br> Error: ${thrownError}`]);
    });
}
