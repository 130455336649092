/* eslint-disable class-methods-use-this */
import localTime from '../imports/localTime';

const { Base } = window.returns.default;

/**
 * Default page class
 */
class Tracking extends Base {
  /**
   * Main functionality of the page
   */
  init() {
    // Replace UTC Date format to local by user OS environment
    localTime();
  }
}

window.returns = { ...window.returns };
window.returns.default = { ...window.returns.default, ...{ Tracking } };

export default Tracking;
