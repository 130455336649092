/* global google */

import $ from 'jquery';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import {
  i18n, warn, parseTemplate, ajaxSettings,
} from '../common/helpers';
import Office from './Office';

class Location {
  constructor($element, {
    points, icons, zip, country, message, center = [0, 0], requiredDrops = [], onRefresh = () => {},
    finder = false,
    searchWidgetClass = 'SearchBox',
  }) {
    this.$el = $element;

    this.$title = $('.js-location-title');
    this.$map = this.$el.find('.location__map');
    this.$labels = this.$el.find('.location__markers');
    this.$search = this.$el.find('.location-search__input');
    this.$gps = this.$el.find('.location-search__button_gps');
    this.$reload = this.$el.find('.location-search__button_reload');
    this.$list = this.$el.find('.location__list');
    this.$noPudo = $('#no-pudo-message');

    this.officeTemplate = $('#location__office-template').html();
    this.lastOfficePosition = null;

    this.geocoder = new google.maps.Geocoder();
    this.zoom = this.constructor.MIN_ZOOM;
    this.country = country;
    this.countryBounds = null;
    this.prevRequest = {
      [this.$search.is('input') ? 'address' : 'zip']: zip,
    };
    this.requiredDrops = requiredDrops;
    this.onRefresh = onRefresh;
    this.finder = finder;
    this.searchWidgetClass = searchWidgetClass;

    this.initMap();
    this.handleReload();
    this.handleAutocomplete();
    this.handleUserLocation();
    this.setMap(message, new google.maps.LatLng(center[0], center[1]), points, icons);

    const self = this;
    this.$list.on(`change${this.constructor.NAMESPACE}`, '.location__office', function officeChange() {
      const marker = $(this).data('office').getMarker();

      if (marker) {
        self.map.panTo(marker.position);
      }
    });
  }

  initMap() {
    this.map = new google.maps.Map(this.$map[0], {
      center: new google.maps.LatLng(0, 0),
      zoom: this.zoom || this.constructor.MIN_ZOOM,
      mapTypeControl: true,
      streetViewControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    });

    this.map.addListener('idle', () => {
      this.center = this.map.getCenter();
      this.zoom = this.map.getZoom();
    });

    this.markers = [];

    this.markerCluster = new MarkerClusterer(this.map, this.markers, {
      ignoreHidden: true,
      gridSize: 80,
      maxZoom: 14,
      styles: [
        {
          width: 35,
          height: 35,
          className: 'cluster-icon-s',
        },
        {
          width: 40,
          height: 40,
          className: 'cluster-icon-m',
        },
        {
          width: 45,
          height: 45,
          className: 'cluster-icon-l',
        },
      ],
      clusterClass: 'cluster-icon',
    });
  }

  getUserMarker() {
    return new google.maps.Marker({
      position: this.userLocation,
      map: this.map,
      icon: {
        path: 'M7 6a3 3 0 0 1 0-6 3 3 0 0 1 0 6zM7 7C3.13 7 0 10.13 0 14c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z',
        fillColor: '#000',
        fillOpacity: 1,
        scale: 1.35,
        strokeColor: '#fff',
        strokeWeight: 0.5,
        anchor: new google.maps.Point(7, 27),
      },
      title: i18n('Your location'),
    });
  }

  getCountryBounds() {
    const dfd = $.Deferred();

    if (this.countryBounds) {
      dfd.resolve(this.countryBounds);
    } else {
      this.geocoder.geocode({
        address: this.country,
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          dfd.resolve(results[0].geometry.viewport);
        }

        dfd.reject(`Geocoder failed to get country info. Status: ${status}`);
      });
    }

    return dfd.promise();
  }

  getIcon(serviceID) {
    return {
      path: 'M25 67C28.7573 67 32.0234 64.6781 34.6822 61.626C37.378 58.5313 39.7371 54.3662 41.6801 49.9466C45.551 41.1419 48 30.7876 48 24.7064C48 12.1396 37.6759 2 25 2C12.3241 2 2 12.1396 2 24.7064C2 30.7876 4.44895 41.1419 8.31989 49.9466C10.2629 54.3662 12.622 58.5313 15.3178 61.626C17.9766 64.6781 21.2427 67 25 67ZM32.6486 24.7064C32.6486 28.8297 29.2509 32.2202 25 32.2202C20.7491 32.2202 17.3514 28.8297 17.3514 24.7064C17.3514 20.5831 20.7491 17.1927 25 17.1927C29.2509 17.1927 32.6486 20.5831 32.6486 24.7064Z',
      fillColor: this.labels[serviceID] ? this.labels[serviceID].color : this.colors[0].value,
      fillOpacity: 1,
      scale: 0.56,
      strokeColor: '#fff',
      strokeWeight: 1,
      anchor: new google.maps.Point(25, 69),
    };
  }

  setMinimalZoom() {
    const zoom = Math.min(this.map.getZoom(), this.constructor.MIN_ZOOM);
    this.map.setZoom(zoom);
  }

  setMap(message, center, points = [], icons = {}) {
    if (this.userLocation) {
      // If user location available then the center parameter is equal to its position
      if (this.userMarker) {
        this.userMarker.setPosition(this.userLocation);
      } else {
        this.userMarker = this.getUserMarker();
      }

      this.markers.push(this.userMarker);

      // Reset user location for next requests
      this.userLocation = null;
    }

    if (points.length > 0) {
      // Show points
      this.$map.parent().removeClass('hidden');
      this.$noPudo.addClass('hidden');

      this.$title.text(i18n('Your nearest drop-off points'));
      this.createLabels(icons);
      this.addPoints(points);
      this.setMinimalZoom();
    } else {
      this.$map.parent().addClass('hidden');
      if ($('.courier').length) {
        this.$noPudo.removeClass('hidden');
      }

      if (message) {
        // Address not found - show full country
        this.getCountryBounds().done((countryBounds) => {
          this.$title.text(message);
          this.map.fitBounds(countryBounds);
          this.map.setZoom(this.map.getZoom() + 1);
        }).fail(error => warn(error));
      } else {
        // Points not found - show map center and user marker on it (if available)
        this.$title.text(i18n('Drop-off points not found'));
        this.map.setCenter(center || this.center);
        this.setMinimalZoom();
      }
    }
  }

  createLabels(icons) {
    this.labels = {};
    this.colors = [
      { name: 'resolution_blue', value: '#001A73' },
      { name: 'tangerine', value: '#ED8C00' },
      { name: 'amber', value: '#FFC400' },
      { name: 'amethyst', value: '#B867C6' },
      { name: 'eastern_blue', value: '#21A09C' },
      { name: 'mountbatten_pink', value: '#977C87' },
      { name: 'conifer', value: '#B8D555' },
      { name: 'violet_red', value: '#FA439E' },
      { name: 'science_blue', value: '#0C74DD' },
      { name: 'black', value: '#000000' },
    ];
    let labels = '';

    Object.keys(icons).forEach((serviceID, index) => {
      const color = this.colors[index % this.colors.length];
      const label = {
        name: icons[serviceID].name,
        color: color.value,
        colorName: color.name,
      };
      this.labels[serviceID] = label;
      labels += this.constructor.createLabel(label);
    });

    this.$labels.append(labels);
  }

  addPoints(points) {
    let $offices = $();

    points.forEach((point) => {
      let marker = null;
      const $office = $(parseTemplate(this.officeTemplate, point));

      if (!this.requiredDrops[point.carrier]) {
        // If drop-off selection is not required for this carrier
        $office.find('.radio').remove();
      }

      if (point.lat !== null && point.lng !== null) {
        const position = new google.maps.LatLng(point.lat, point.lng);

        marker = new google.maps.Marker({
          map: this.map,
          position,
          draggable: false,
          icon: this.getIcon(point.service),
          title: point.title,
          $office,
        });

        marker.addListener('click', () => {
          const currentOfficePosition = $office.position().top - 5;
          if (this.lastOfficePosition === currentOfficePosition) {
            return;
          }
          const $scroller = this.$list.parent();
          $scroller.scrollTop(0);
          $scroller.animate({
            scrollTop: $scroller.scrollTop() + currentOfficePosition,
          }, 500);

          this.lastOfficePosition = currentOfficePosition;
        });

        this.markers.push(marker);
      }

      $office.data('office', new Office($office, marker, 'select_shop'));
      $offices = $offices.add($office);
    });

    this.markerCluster.addMarkers(this.markers);

    this.$list.append($offices);
    const latLngBounds = this.markers.reduce((bounds, marker) => {
      bounds.extend(marker.position);
      return bounds;
    }, new google.maps.LatLngBounds());
    this.map.setCenter(latLngBounds.getCenter());
    // Show all markers for the proper clustering (should be fixed when markers outside
    // the viewport will automatically hide)
    this.map.fitBounds(latLngBounds);

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      this.setMinimalZoom();
    });
  }

  clear() {
    this.$labels.empty();
    this.$list.empty();
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
    this.markers = [];
  }

  refresh(position, types) {
    if (this.finder) {
      // For drop-off points finder
      this.map.setZoom(this.constructor.MIN_ZOOM);
      this.setMap(false, position);
    } else {
      // For select shipping
      const searchString = $.trim(this.$search.val());
      const data = {};

      if (this.$search.is('input')) {
        // If search input is textbox then looking for the free-form address
        data.address = searchString;
      } else {
        // If search input is dropdown (for Hong Kong and Malta) then zip is explicitly known
        data.zip = searchString;
      }

      if (position) {
        // User has selected an option from Google SearchBox or have tried to locate himself
        data.lat = position.lat();
        data.lng = position.lng();
      }

      if (types) {
        // User has selected an option from Google SearchBox
        if (types.indexOf('country') !== -1) {
          data.type = 'country';
        }

        if (types.indexOf('continent') !== -1) {
          data.type = 'continent';
        }
      }

      // If the search request is different from the previous one then get new points
      // Order of properties is constant so we can use JSON.stringify for simplicity
      if (JSON.stringify(this.prevRequest) !== JSON.stringify(data)) {
        this.$gps.prop('disabled', true);
        this.constructor.toggleButton(this.$reload, true, i18n('Looking for new drop-off points...'));

        const settings = {
          url: '/main/get_new_marks_for_map',
          method: 'GET',
          data,
          dataType: 'json',
          noPreloader: true,
        };
        const debugSettings = {
          page: 'shipping',
          actions: ['not-found', 'points', 'no-points'],
          noNext: true,
        };

        $.ajax(ajaxSettings(settings, debugSettings)).done((json) => {
          this.clear();
          this.requiredDrops = json.carriers_select_drop_off_ponts;
          this.setMap(json.error, position, json.map_marks, json.map_marks_icons);
          this.prevRequest = data;

          this.onRefresh();
        }).always(() => {
          this.$gps.prop('disabled', false);
          this.constructor.toggleButton(this.$reload, false, i18n('Look for new drop-off points'));
        });
      }
    }
  }

  handleReload() {
    this.$reload.on(`click${this.constructor.NAMESPACE}`, () => this.refresh());

    if (this.$search.is('select')) {
      this.$search.on(`change${this.constructor.NAMESPACE}`, () => this.refresh());
    }
  }

  handlePlaceChanges(widget, eventName, placeResolver) {
    widget.addListener(eventName, () => {
      const place = placeResolver();
      if (place) {
        // If user have chosen any element from search results then reload map
        this.refresh(place.geometry.location, place.types);
      }
    });
  }

  handleAutocomplete() {
    if (this.$search.is('input')) {
      let searchWidget;
      switch (this.searchWidgetClass) {
        case 'Autocomplete':
          searchWidget = new google.maps.places.Autocomplete(this.$search[0], {
            fields: ['geometry.location', 'types'],
          });
          searchWidget.bindTo('bounds', this.map);
          this.handlePlaceChanges(searchWidget, 'place_changed', () => searchWidget.getPlace());
          break;

        default:
          searchWidget = new google.maps.places.SearchBox(this.$search[0]);
          searchWidget.bindTo('bounds', this.map);
          this.handlePlaceChanges(searchWidget, 'places_changed', () => {
            const places = searchWidget.getPlaces();
            return (places.length !== 0) ? places[0] : null;
          });
      }

      this.$search.on(`keydown${this.constructor.NAMESPACE}`, (event) => {
        if (event.keyCode === 13) {
          // Don't send a full form if user pressed "Enter" key
          event.preventDefault();

          // If user enters some text and search results is empty then reload map
          const isSearchResultsHidden = !$('.pac-container:visible').length;
          if (isSearchResultsHidden) {
            this.refresh();
          }
        }
      });
    }
  }

  handleUserLocation() {
    this.userLocation = null;
    this.userMarker = null;

    if (window.navigator.geolocation) {
      this.$search.addClass('location-search__input_gps');

      this.$gps.on(`click${this.constructor.NAMESPACE}`, (event) => {
        event.preventDefault();

        this.$gps.removeClass(this.constructor.GPS_ERROR);
        this.constructor.toggleButton(this.$gps, true, i18n('Looking for your position...'));

        navigator.geolocation.getCurrentPosition((position) => {
          // Get user location
          this.userLocation = new google.maps.LatLng(
            position.coords.latitude, position.coords.longitude,
          );

          this.geocoder.geocode({
            latLng: this.userLocation,
          }, (results, status) => {
            this.constructor.toggleButton(this.$gps, false, i18n('Find near my location'));

            if (status === google.maps.GeocoderStatus.OK && results[0]) {
              this.$search.filter('input').val(results[0].formatted_address);
              this.refresh(this.userLocation);
            }
          });
        }, (error) => {
          warn(error.message);

          let message;
          let isError = false;
          switch (error.code) {
            case 1: // PERMISSION_DENIED
              message = i18n('User denied Geolocation');
              isError = true;
              break;
            case 2: // POSITION_UNAVAILABLE
              message = i18n('Position unavailable');
              isError = true;
              break;
            case 3: // TIMEOUT
              message = i18n('Timeout expired');
              break;
            default: // Something else
              message = i18n('Unexpected error');
              break;
          }

          this.$gps.toggleClass(this.constructor.GPS_ERROR, isError);
          this.constructor.toggleButton(this.$gps, false, message);
        }, { timeout: 5000 });
      });
    }
  }

  static createLabel(label) {
    return `<span class="marker location__marker"><span class="icon icon_marker marker__icon marker__icon_${label.colorName}"></span>${label.name}&lrm;</span>`;
  }

  static toggleButton($button, isDisabled, message) {
    $button
      .prop('disabled', isDisabled)
      .attr('title', message)
      // .attr('aria-label', message);
      .find('.hidden_sr')
      .text(message);
  }
}

Location.NAMESPACE = '.location';
Location.MIN_ZOOM = 17;
Location.GPS_ERROR = 'location-search__button_gps_error';
Location.INFO_WINDOW = 'info-window';

export default Location;
