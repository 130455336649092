import { formatPrice } from '../common/helpers';

class OrderProduct {
  /**
   * Class constructor
   * @param {jQuery} $element
   * @param {Object} options
   * @param {Object} [params]
   */
  constructor($element, options, params = {}) {
    this.options = {
      ...OrderProduct.DEFAULTS,
      ...params,
    };

    this.$el = $element;
    this.$quantity = this.$el.find('.js-quantity');
    this.$price = this.$el.find('.js-price');
    this.$total = this.$el.find('.js-total');
    this.$toggle = this.$el.find(this.options.TOGGLE);
    this.$remove = this.$el.find(this.options.REMOVE);

    this.collapsed = this.$el.hasClass(this.options.COLLAPSED);
    this.unremovable = false;
    this.currency = options.currency || this.options.CURRENCY;

    this.handleToggle();
    this.handleRemove();
    this.handleTotal();
  }

  calcTotal(newCurrency) {
    if (newCurrency) {
      this.currency = newCurrency;
    }

    const price = Math.max(0, this.$quantity.val() * this.$price.val());
    this.$total.val(`${formatPrice(price)} ${this.currency}`);
  }

  isCollapsed() {
    return this.collapsed;
  }

  toggle(show) {
    const toggle = (typeof show !== 'undefined') ? show : this.isCollapsed();

    this.$el.trigger(`${toggle ? 'expand' : 'collapse'}${this.options.NAMESPACE}`);

    this.$el.toggleClass(this.options.COLLAPSED, !toggle);
    this.$toggle.toggleClass('button_toggle_collapse', !toggle);
    this.collapsed = !toggle;
  }

  expand() {
    this.toggle(true);
  }

  collapse() {
    this.toggle(false);
  }

  remove() {
    if (this.unremovable) {
      return;
    }

    this.$el.addClass(this.options.REMOVING).trigger(`remove${this.options.NAMESPACE}`);
    this.$el.remove();
  }

  setAsUnremovable() {
    this.$remove.hide();
    this.unremovable = true;
  }

  setAsRemovable() {
    this.$remove.show();
    this.unremovable = false;
  }

  handleToggle() {
    this.$toggle.on(`click${this.options.NAMESPACE}`, () => {
      this.toggle();
    });
  }

  handleRemove() {
    this.$remove.on(`click${this.options.NAMESPACE}`, () => {
      this.remove();
    });
  }

  handleTotal() {
    // Update on init
    this.calcTotal();

    // Update when currency changed remotely
    this.$el.on(`currency${this.options.NAMESPACE}`, (event, currency) => this.calcTotal(currency));

    // Update when quantity or price changed
    this.$quantity.add(this.$price).on(`keyup${this.options.NAMESPACE} change${this.options.NAMESPACE}`, () => this.calcTotal());
  }
}

OrderProduct.DEFAULTS = {
  PRODUCT: '.order__product',
  COLLAPSED: 'order__product_collapsed',
  COLLAPSIBLE: '.js-collapsible',
  REMOVING: 'js-removing',
  CURRENCY: 'USD',
  NAMESPACE: '.product',
  TOGGLE: '.order__toggle',
  REMOVE: '.order__remove',
};

export default OrderProduct;
