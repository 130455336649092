/* eslint-disable class-methods-use-this */

import { resetFieldError } from '../common/helpers';
import Option from './Option';

class Payment extends Option {
  init() {
    this.$info = this.$el.next(this.constructor.INFO);
    this.$fields = this.$info.find('input, select, textarea');
  }

  toggleSelection(toggle) {
    // If state was changed
    if (super.toggleSelection(toggle)) {
      // If payment type is selected - enable associated fields
      this.$fields.prop('disabled', !toggle);

      if (!toggle) {
        // If payment type was deselected then hide validation errors
        resetFieldError(this.$fields);
      }
    }
  }
}

const NAME = 'payment';

Payment.ITEM = `.${NAME}__type`;
Payment.ITEM_SELECTED = `${NAME}__type_selected`;
Payment.ITEM_DISABLED = `${NAME}__type_disabled`;
Payment.INFO = `.${NAME}__info`;
Payment.RADIO = '.radio__input';
Payment.NAMESPACE = `.${NAME}`;

export default Payment;
