import $ from 'jquery';

class Preloader {
  constructor() {
    this.$el = $(Preloader.ELEMENT);
    this.$page = $(Preloader.PAGE);
    this.$content = this.$el.find(Preloader.CONTENT);
    this.focused = false;
    this.messages = [];
    this.currentMessage = 0;
    this.messageTimer = null;

    // Add small pause between page load and preloader hiding
    setTimeout(() => this.focus(), Preloader.DURATION);
    // On iOS preloader will be visible and won't hide when user clicked back button
    // So have to use additional trigger to hiding preloader
    $(window).on('pageshow', () => this.focus());
    this.getMessages();
  }

  blur() {
    this.focused = false;
    this.$el.stop().fadeIn(Preloader.DURATION, () => this.showMessage());
    this.$page.addClass(Preloader.PAGE_BLURRED);
  }

  focus() {
    if (this.focused) {
      return;
    }

    this.focused = true;
    this.currentMessage = 0;
    this.$el.stop().fadeOut(Preloader.DURATION, () => {
      $('[autofocus]').focus();
      this.resetMessages();
    });
    this.$page.removeClass(`${Preloader.PAGE_BLURRED} ${Preloader.PAGE_HIDDEN}`);
  }

  getMessages() {
    if (!window.returns) {
      return;
    }

    const { preloaderMessages } = window.returns;
    if (Array.isArray(preloaderMessages)) {
      this.messages = preloaderMessages.map(({ time, text }) => ({
        time: Number.parseInt(time, 10) * 1000,
        text,
      })).sort((a, b) => (a.time - b.time));
    }
  }

  showMessage(prevTime = 0) {
    const message = this.messages[this.currentMessage];

    if (message) {
      this.messageTimer = setTimeout(() => {
        this.$content.text(message.text);
        this.currentMessage += 1;
        this.showMessage(message.time);
      }, message.time - prevTime);
    }
  }

  resetMessages() {
    this.$content.text('');
    this.currentMessage = 0;
    clearTimeout(this.messageTimer);
  }
}

Preloader.ELEMENT = '.preloader';
Preloader.CONTENT = '.preloader__content';
Preloader.PAGE = '.page';
Preloader.PAGE_BLURRED = 'page_blurred';
Preloader.PAGE_HIDDEN = 'page_hidden';
Preloader.DURATION = 300;

const preloader = new Preloader();
$(Preloader.ELEMENT).data('preloader', preloader);

export default preloader;
