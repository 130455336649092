import $ from 'jquery';

class RadioGroup {
  constructor($group, {
    list = [],
    name = 'select_shop',
    onCheck = () => {},
    namespace = RadioGroup.NAMESPACE,
  }) {
    this.$group = $group;
    this.$radios = $(); // this.$group.find(RadioGroup.ELEMENT);
    this.list = list;
    this.name = name;
    this.onCheck = onCheck;
    this.namespace = namespace;

    this.$checked = $();

    this.$group.on({
      [`click${this.namespace}`]: this.clickHandler.bind(this),
      [`keydown${this.namespace}`]: this.keydownHandler.bind(this),
    }, RadioGroup.ELEMENT);
  }

  clickHandler(event) {
    if (event.type !== 'click') {
      return;
    }

    const $this = $(event.currentTarget);
    this.uncheck(this.$checked);
    this.check($this);
  }

  keydownHandler(event) {
    if (event.type !== 'keydown') {
      return;
    }

    const $this = $(event.currentTarget);
    let $next = $();

    switch (event.keyCode) {
      case RadioGroup.KEYCODE.DOWN:
      case RadioGroup.KEYCODE.RIGHT:
        $next = $this.next(RadioGroup.ELEMENT);

        if (!$next.length) {
          $next = this.$radios.first();
        }
        break;

      case RadioGroup.KEYCODE.UP:
      case RadioGroup.KEYCODE.LEFT:
        $next = $this.prev(RadioGroup.ELEMENT);

        if (!$next.length) {
          $next = this.$radios.last();
        }
        break;

      case RadioGroup.KEYCODE.SPACE:
        $next = $this;
        break;

      default:
        break;
    }

    if ($next.length) {
      // Prevent default behaviour only for specified keys
      event.preventDefault();

      this.uncheck(this.$checked);
      this.check($next);
    }
  }

  check($radio) {
    $radio.addClass('office_selected').attr({
      'aria-checked': true,
      tabindex: 0,
    }).focus();

    this.toggleInput($radio, true);
    this.$checked = $radio;

    this.onCheck($radio);
  }

  uncheck($radio) {
    $radio.removeClass('office_selected').attr({
      'aria-checked': false,
      tabindex: -1,
    });

    this.toggleInput($radio, false);
    this.$checked = $();
  }

  toggleInput($radio, state) {
    const $input = $radio.find(':radio');
    const isChecked = $input.prop('checked');

    if ($input.length && state !== isChecked) {
      $input.prop('checked', state).trigger(`change${this.namespace}`);
    }
  }
}

RadioGroup.KEYCODE = {
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  UP: 38,
};
RadioGroup.ELEMENT = '[role="radio"]';
RadioGroup.NAMESPACE = '.radiogroup';

export default RadioGroup;
