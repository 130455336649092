import $ from 'jquery';
import { warn } from './common/helpers';

// Import base page class
import './imports/Base';

// Import default page classes
import './pages/Landing';
import './pages/Step1';
import './pages/Step2';
import './pages/Step3';
import './pages/Confirm';
import './pages/Shipping';
import './pages/Payment';
import './pages/Label';
import './pages/Help';
import './pages/Finder';
import './pages/Tracking';

// Expose jQuery to global scope for plugins
window.$ = $;
window.jQuery = $;

$(() => {
  if (window.returns) {
    const pages = { ...{}, ...window.returns.default, ...window.returns.theme };
    const { page } = window.returns;

    const globalOptions = {
      validator: {
        inputInvalid: 'input_state_invalid',
        inputValid: 'input_state_valid',
        errorClass: 'field__error',
        validClass: 'field__error_hide',
      },
    };

    if (typeof page === 'string') {
      if (pages[page]) {
        new pages[page](globalOptions); // eslint-disable-line no-new
      } else {
        warn(`Page not found: '${page}'`);
        new pages.Base(globalOptions); // eslint-disable-line no-new
      }
    } else {
      warn(`Incorrect page name: '${page}' [${typeof page}]`);
      new pages.Base(globalOptions); // eslint-disable-line no-new
    }
  }
});
